@import '../../../scss/_web_base';

.changePasswordWrapper {
    width: 100%;
    @include themify($themes){
      font-family: themed(font-family);
    }
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-bottom: 10px;
    .confirmRow{
      height: 42px;
      width: 100%;
      justify-content: center;
      align-items: center;
      .confirmButton {
        cursor: pointer;
        width: 84px;
        height: 28px;
        margin: 0px 5px;
        &.confirm{
            @include themify($themes){
                background: themed(theme-primary-color);
                border-color:themed(theme-primary-color);
              }
            &:hover{
                @include themify($themes){
                    background: themed(theme-secondary-color);
                    border-color:themed(theme-secondary-color);
                  }
            }
        }
        &.reset{
            background-color: #F56C6C;
            border-color: #F56C6C;
            &:hover{
                background: #f78989 !important;
                border-color: #f78989 !important;
            }
        }
        color: $white;
        border-radius: 4px;
        font-size: 12px;
        text-align: center;
        line-height: 28px;
      }
    }
    .changePasswordContentWrapper{
        border: 1px solid #ddd;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    .changePasswordContent {
      width: 100%;
      border-radius: 3px;
        @include themify($themes){
          border: 1px solid themed(table-border-color);
        }
      .inputRow {
        width: 100%;
        &:not(:last-child){
          @include themify($themes){
            border-bottom: 1px solid themed(table-border-color);
          }
        }
        font-size: 13px;
        padding: 0px;
        margin: 0px;
        height: 40px;
        .el-input {
          height: 30px !important;
          .el-input__inner {
            height: 30px !important;
            background-color: transparent;
          }
        }
        .inputHeader {
          padding: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: normal;
          color:  #5D6D78;
          @include themify($themes){
            background-color: themed(theme-third-color);
            border-right: 1px solid themed(table-border-color);
          }  
        }
        .inputError{
          display: inline-flex;
          align-items: center;
          color:$red;
        }
      }
    }
}
  }