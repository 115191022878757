@import '../../../scss/web_base';

.pagination{
  padding-top: 1%;

  .box{
    cursor: pointer;
    font-weight: 700;
    color: rgb(95, 95, 95);
    padding: 5px;
    min-width: 2rem;
    min-height: 1.7rem;
    text-align: center;
    border-radius: 2px;
    font-size: 12px;
    background: rgb(248, 248, 248);
    @include themify($themes){
      color: themed(pagination-text-color);
    }
  }

  .paginationActive{     
    color: $white !important;
    @include themify($themes){
      border:1px solid themed(theme-primary-color);
      background-color: themed(theme-primary-color);
    }
  }

  .inactive {
    color:rgb(187, 187, 187);
  }

  input{
    width: 2.3rem;
    height: 1.6rem;
    font-size: 12px;
    text-align: center;
  }
}