@import '../../scss/web_base';

.reportManagementWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  .betQueryWrapper{
    padding: 0px !important;
  }

  .numberRed{
    color: #f70824 !important;
  }
  .numberBlue{
    color: #448eed !important;
  }
  .detailLink{
    cursor: pointer;
  }
}