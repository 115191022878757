@import '../../../scss//web_base';

.LotteryManageWrapper {
  position: relative;

  .text-red{
    color:red;
  }

  .noWrapStyle{
    white-space: nowrap;
    padding: 0px 20px;
  }
  .oldValueStyle,.newValueStyle{
    max-width: 300px;
    text-align: left;
  }
  .form-check-input[type=checkbox] {
    border-radius: 0.15em;    }
  .el-input__inner {
    font-size: 12px;
    height: 30px;
  }
  .filterWrapper {
    color: #5d6d7b;
    font-size: 12px;
    border-radius: 3px;
    width: 100%;
    @include themify($themes){
      border: 1px solid themed(table-border-color);
      background-color: themed(theme-third-color);
    }
  }
  .button {
    cursor: pointer;
    color: $white;
    border-radius: 5px;
    outline: none;
    font-size: 12px;
    position: relative;
    padding: 3px 5px;
    box-shadow: none;
    &.add{
      background: rgb(249, 108, 65);
      border: 1px solid #f56f62;
    }
    &.find{
      background: #f56f62;
      border: 1px solid #f56f62;
    }
    &.handle{
      background-color: #409EFF;
      border-color: #409EFF;
    }
    &.green{
      background-color: #67C23A;
      border-color: #67C23A;
    }
  }
  .bigWrapper {
    box-shadow: 0 0px 10px rgba(0,0,0,0.15);
    transition: box-shadow 0.3s ease-in-out;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: 100%;
  }
}