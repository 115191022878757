@import '../../../scss/web_base';

.SafePhoneModalWrapperWeb {
  width: 500px;
  @include themify($themes) {
    font-family: themed(font-family);
  }
  .operationBtn{
    border-radius: 5px;
    padding: 7px 10px;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    color: #FFF;
    @include themify($themes){
      background: themed(theme-primary-color);
      border-color:themed(theme-primary-color);
    }
    &:hover{
        @include themify($themes){
            background: themed(theme-secondary-color);
            border-color:themed(theme-secondary-color);
          }
    }

    &.reset{
      background-color: #E6A23C;
      border-color: #E6A23C;
      &:hover{
          background: #ebb563 !important;
          border-color: #ebb563 !important;
      }
  }

    &.add, &.cancel{
      background-color: #F56C6C;
      border-color: #F56C6C;
      &:hover{
          background: #f78989 !important;
          border-color: #f78989 !important;
      }
      .plus_icon{
        margin-right: 3px;
        border-radius: 50%;
        padding: 0px 2px;
        display: inline;
        background-color: white !important;
      .el-icon-plus{
        color: #F56C6C;
      }
    }
    }
  }
  .modalHeader {
    line-height: 45px;
    color: $white;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
      font-family: themed(font-family);
    }
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
    .closeIcon {
      cursor: pointer;
    }
  }
  .modalContent {
    font-size: 13px;
    .orderInformationTable{
      border: 1px solid #ddd;
      width: 100%;
      .el-input{
          width: 200px;
        }

        .el-input__inner{
          height: 30px !important;
        }
   
        .el-select-dropdown__item.selected,
        .el-select-dropdown__item.selected.hover {
          font-weight: 700;
          @include themify($themes){
            color: themed(theme-primary-color);
            background-color: themed(drop-down-hover);
          }
        }

        .el-select {
          .el-input__inner:hover, .el-input__inner:focus {
            @include themify($themes){
              border-color: themed(theme-primary-color);  
              background-color: themed(drop-down-hover);
            }
          }
          .el-input__inner {      
            width: 200px;
            padding-right: 10px;
            .is-active {
            @include themify($themes){
              color: themed(theme-primary-color);
              border-color:themed(theme-primary-color);
            }
          }
          }   
        }  
        .el-select-dropdown__item:hover {
          @include themify($themes){
            background-color: themed(drop-down-hover);
          }
        }
        .el-select-dropdown {
          border-radius: 4px !important;
        }
      .evenRowStyle{
          display: flex;
          &>div:nth-child(odd){
            @include themify($themes) {
              background-color:themed(theme-third-color);
            }
              width: 30%;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              padding-right: 20px;
          }
          &>div:nth-child(even){
              flex: 1;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding-left: 20px;
          }
          &>div:not(:last-child){
              border-right: 1px solid #ddd;
          }
      }
      &>div{
          min-height: 40px;
      }
      &>div:not(:last-child){
          border-bottom: 1px solid #ddd;
      }
      .orderInformationRowBackGroundColor{
        @include themify($themes) {
          background-color:themed(theme-third-color);
        }
      }
  }
  }
  .modalFooter {
    font-size: 16px;
    height: max-content;
    margin: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    @include themify($themes) {
      border-top: 1px solid #dcdcdc
    }
    .confirmButton {
      width: 120px;
      height: 36px;
      color: $white;
      border-radius: 4px;
      font-size: 16px;
      text-align: center;
      @include themify($themes){
        background-color: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
    }
  }
}