@import '../../scss/web_base';

.dataTable {
  width: 400px;
  padding: 0;
  margin: 0 4px;
  border: 1px solid $border; 
  height: max-content;
  @include themify($themes) {
    color: themed(data-table-text-color);
  }
  .keyCodeCategoryWrapper {
    padding-left: 0;
    border-bottom: 1px solid $border; 
    .categoryLabel {
      font-size: 12px;
      width: 50px;
      display: inline-block;
      padding-bottom: 10px;
      text-align: center;
      cursor: pointer;
      &.active {
        @include themify($themes){
          border-bottom: 4px solid themed(theme-primary-color);
        }
      }
    }
  }

  .templateStyle{
    border-collapse: collapse;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;

    thead {
      tr {
        td {
          &:first-child {
            border-left: 0;
          }
        }
        td {
          &:last-child {
            border-right: 0;
          }
        }
        &:first-child {
          td {
            border-top: 0;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 0;
          &:first-child {
            border-left: 0;
          }
          &:last-child {
            border-right: 0;
          }
        }
        &:first-child {
          td {
            border-top: 0;
          }
        }
        &:last-child {
          td {
            border-bottom: 0;
          }
        }
      }
    }
    
    .header-bg{
      @include themify($themes){
        background-color: themed(theme-third-color);
      }
    }
    .table-title{
      &>div:not(:last-child){
        border-right: 1px solid $border; 
      }
    }
    .table-cell {
      display: flex;
      width: 100%;
      border: 0;
      border-bottom: 1px solid $border;
      &:hover {
        @include themify($themes){
          background-color: themed(theme-third-color);
        }
      }
      > div {
        justify-content: center;
        display: flex;
        align-items: center;
        border-right: 1px solid $border;
        &:last-child {
          border: 0;
        }
      }
    }

    .column-number {
      min-width: 79px !important;
      width: 20%;
      height: 38px;
    }
    .column-odds {
      min-width: 79px !important;
      width: 20%;
      height: 38px;
    }
    .column-amount {
      min-width: 79px !important;
      width: 20%;
      height: 38px;
    }
    .column-profit {
      min-width: 79px !important;
      width: 20%;
      height: 38px;
    }
    .column-buhuo {
      min-width: 79px !important;
      width: 20%;
      height: 38px;
    }
    th {
      border: 1px solid $border; 
    }

    td{
      border: 1px solid $border; 
      padding:0px;
    }
  }
}