@import '../../../scss/web_base';

.rowselected{
    @include themify($themes){
        background-color: themed(in-time-selected) !important;
        }
    }
    .inTimeAmount{
        cursor: pointer;
    }

.buhuoDone{
    cursor: pointer;
    @include themify($themes){
        color: themed(theme-primary-color);
        border-bottom: 1px solid themed(theme-primary-color);
    }
}

.oddsControlWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .button_do{
        width: 15px;
        height: 15px;
        cursor: pointer;
    }
    .odd{
        width: 50%;
        text-align: center;
        @include themify($themes){
            color: themed(odds-color);
        }
    }
   }

   td{
    height: 100% !important;
   }