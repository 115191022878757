@import '../../../scss/web_base';

.lotteryReorderModalWrapper {
  border-radius: 5px;
  width: 350px;
  @include themify($themes) {
    font-family: themed(font-family);
  }
  .blue {
    @include themify($themes) {
      color: themed(theme-primary-color);
      font-family: themed(font-family);
    }
  }
  .modalHeader {
    line-height: 34px;
    color: $white;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
      font-family: themed(font-family);
    }
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    .closeIcon {
      cursor: pointer;
    }
  }
  .drawhalfbox {
    @include themify($themes){
        border-left: 1px solid themed(table-border-color);
        border-right: 1px solid themed(table-border-color);
        border-bottom: 1px solid themed(table-border-color);
      }
  }
  .myClass {
    text-align: left;
    padding: 3px;
    margin: 3px 0 3px 0;
    @include themify($themes) {
      color: themed(theme-primary-color);
      border: themed(theme-primary-color) 1px solid;
      border-radius: 5px;
    }
    -webkit-user-drag: none;
  }
  .modalContent {
    width: 100% !important;
    background-color: white;
    font-size: 12px;
    margin: 0;
    max-height: 500px;
    padding-bottom: 2em;
    color: #5d6d7b;
    border:none;
    @include themify($themes) {
      font-family: themed(font-family);
    }
    .confirmText{
      text-align: center;
    }
    .form-check-input[type=checkbox] {
      border-radius: 0.15em;    }
    .exclamationIcon {
      height: 7em;
      width: 7em;
      circle{
        @include themify($themes){
          fill: themed(theme-secondary-color) !important;
        }
      }
    }
    .successText {
      @include themify($themes) {
        color: themed(success-modal-text-color)
      }
    }
    .box {
      border: 1px solid #ccc;
      padding: 4px;
      border-radius: 4px;
      color: #666;
      cursor: move;
      text-align: left;
    }
    .boxDropped {
      @include themify($themes) {
        border: themed(theme-primary-color) 1px solid;
      }
      border: 1px solid #ccc;
      padding: 4px;
      border-radius: 4px;
      color: #666;
      cursor: move;
      text-align: left;
    }
  }
  .el-input__inner {
    font-size: 12px;
    height: 30px;
  }
  .loadingwrapper {
    background-color:#000;
    opacity: 0.3;
    position: absolute;
    padding-top: 30%;
    height: 100%;
    width: 100%;
    z-index: 5;
  }
  .tableWrapper {
    max-height: 400px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(212, 212, 212);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgb(204, 204, 204);
    }
  }
  .modalFooter {
    font-size: 16px;
    margin: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    @include themify($themes) {
      background-color:themed(theme-third-color);
    }
    .confirmButton {
      width: 120px;
      height: 36px;
      color: $white;
      border-radius: 4px;
      font-size: 16px;
      text-align: center;
      @include themify($themes){
        background-color: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
    }
  }
}

