@import "../../../scss/web_base";

.authLayout {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  @include themify($themes) {
    font-family: themed(font-family);
  }

  .authContentArea{
    flex: 1;
    overflow-y: auto;
    height: 100%;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background: #ADACAC;
      border-radius: 5px;
    }
  }
  .sideLanguage{
    font-size: 12px;
    position: fixed;
    right: 0px;
    bottom: 70px;
    box-shadow: 0 0 10px rgba(0, 0, 0 ,0.3);
    z-index: 10;

    &:hover{
      .menu{
        display: block !important;
      }
    }

    .title{
      width: 60px;
      height: 40px;
      text-align: center;
      color: white;
      border-radius: 0;
      border: none;
      line-height: 40px;
      cursor: pointer;
      background-color: #597598;
      &:hover{
        background-color: #4f96ca;
      }
    }
    .menu{
      position: absolute;
      display: none;
      top: -60px;
      right: 0px;
      &:hover{
        display: block;
      }
      &:hover+div.title{
        background-color: #4f96ca;
      }
    }
    .hoverMenu{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100px;
      border-radius: 3px;
      height: 60px;
      background-color: #fff;
      border: 1px solid #cccccc;
      box-sizing: border-box;
      cursor: pointer;
      &>div{
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          margin-right: 10px;
        }
        &:hover{
          color: red;
        }
      }
    }
  }
}
