@import '../../../../scss//web_base';

.MemberEditWrapper {
  position: relative;

  .textCenter {
    text-align: center;
  }

  .text-red {
    color: red;
  }

  .w-select {
    width: 200px;
  }

  .operationBtn {
    border-radius: 5px;
    padding: 7px 10px;
    white-space: nowrap;
    cursor: pointer;
    color: #FFF;

    @include themify($themes) {
      background: themed(theme-primary-color);
      border-color: themed(theme-primary-color);
    }

    &:hover {
      @include themify($themes) {
        background: themed(theme-secondary-color);
        border-color: themed(theme-secondary-color);
      }
    }

    &.reset,
    &.cancel {
      background-color: #F56C6C;
      border-color: #F56C6C;

      &:hover {
        background: #f78989 !important;
        border-color: #f78989 !important;
      }

      .plus_icon {
        margin-right: 3px;
        border-radius: 50%;
        padding: 0px 2px;
        display: inline;
        background-color: white !important;

        .el-icon-plus {
          color: #F56C6C;
        }
      }
    }
  }

  .w-single-checkbox {
    width: 160px;
  }

  .noWrapStyle {
    white-space: nowrap;
    padding: 0px 20px;
  }

  .button {
    color: $white;
    font-weight: 700;
    border-radius: 5px;
    outline: none;
    font-size: 12px;
    position: relative;
    padding: 5px 15px 5px 15px;
    box-shadow: none;

    &.find {
      background: #f56f62;
      border: 1px solid #f56f62;
    }

    &.handle {
      background-color: #409EFF;
      border-color: #409EFF;
    }
  }

  .bigWrapper {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.3s ease-in-out;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: 100%;
  }

  .titleWrapper {
    border: 1px solid #dcdcdc;
    color: #5d6d7b;
    font-size: 14px;
    font-weight: bold;
    padding: 15px 15px 15px 20px;
    display: flex;
    justify-content: center;

    @include themify($themes) {
      background-color: themed(theme-third-color);
    }
  }

  td,
  th {
    color: #5d6d7b;
    font-size: 12px;
    font-weight: normal;
    padding: 6px 8px;
    margin: 0 auto;

    @include themify($themes) {
      border: 1px solid themed(table-border-color);
    }

    vertical-align: middle;
  }

  .tableHeader {
    @include themify($themes) {
      background-color: themed(theme-third-color);
      color: themed(table-header-text-color);
    }

    font-weight: normal;
    text-align: right;
  }

  tbody {
    tr {
      .topLeftBorderNone {
        border-top: none !important;
        border-left: none !important;
      }
    }
  }

  th {
    font-weight: normal !important;
  }
}