@import '../../scss/web_base';

.dataTableItem {
  .buhuoWrapper {
    display: flex;
    flex-direction: column;
    span {
      line-height: 12px;
      &:first-child {
        text-decoration: underline;
        @include themify($themes) {
          color: themed(theme-primary-color);
        }
      }
    }
  }
}