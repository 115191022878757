@import '../../../scss/web_base';

.menuAmount{
    height: 18px;
    text-align: center;
    @include themify($themes){
        color: themed(menu-amount);
    }
}
.subMenuPanel {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    margin-bottom: 10px;
    @include themify($themes){
        border: 1px solid themed(table-border-color);
    }
    padding: 4px 0px;
    &.childMenuPanel{
        &>div{
            cursor: pointer;
        }
        &>div:not(:last-child){
            @include themify($themes){
                border-right: 1px solid themed(table-border-color);
            }
        }
    }
    .menuItem{
        cursor: pointer;
        padding: 0px 15px;
        font-weight:600 !important;
        .menuItemTitle{
            height: 30px;
            line-height: 30px;
            @include themify($themes){
                border-bottom: 1px solid themed(table-border-color);
            }
            &.selected,&:hover{
                @include themify($themes){
                    color: themed(theme-primary-color) !important;
                    border-bottom: 2px solid themed(theme-primary-color) !important;
                }
            }
        }
    }
}