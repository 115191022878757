@import '../../../scss/_web_base';


.BigBetLimitWrapper {
  font-size: 12px;
  color: #5d6d7b;
  position: relative;

  .input {
    width: 100px;
  }

  .footerAction {
    display: flex;
    align-items: center;
    justify-content: center;
    // gap: 4px;
  }

  .tableTimeSet {
    padding: 0;
    display: flex;
    align-items: center;

    .text {
      min-width: max-content;
    }
  }

  .button {
    color: $white;
    font-weight: 700;
    border-radius: 5px;
    outline: none;
    font-size: 12px;
    position: relative;
    padding: 5px 15px 5px 15px;
    box-shadow: none;

    &.save {
      background: #f56f62;
      border: 1px solid #f56f62;
    }

    &.cancel {
      background-color: #409EFF;
      border-color: #409EFF;
    }
  }

  .tabList {
    padding: 0px;
    display: flex;
    align-items: center;
    gap: 4px;

    .tabItem {
      min-width: 120px;
      cursor: pointer;
      color: white;
      padding: 5px 10px;

      @include themify($themes) {
        border: 1px solid themed(table-border-color);
        background-color: themed(theme-primary-color);

      }

      &.active {
        @include themify($themes) {
          border: 1px solid themed(table-border-color);
        }

        background-color: #de4521;
      }
    }
  }

  .pl-2 {
    padding-left: 10;
  }

  .lotteryList {
    @include themify($themes) {
      border: 1px solid themed(table-border-color);
    }

    font-weight: bold;
    // max-width: 1380px;
    min-width: 975px; // same min width as filter wrapper in this file 
    background-color: #ffeee3;
    display: flex;
    align-items: center;
    overflow-x: auto;
    padding-top: 10px;
    padding-bottom: 10px;

    .lotteryItem {
      text-align: center;
      cursor: pointer;
      padding: 10px;
      min-width: max-content;

      &.active {
        @include themify($themes) {
          border: 1px solid themed(table-border-color);
        }

        background-color: white;
      }
    }

  }


  .filterWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .marginLeft1 {
    margin-left: 4px;
  }

}