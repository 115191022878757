@import '../../../scss/web_base';

.OnlineRecordWrapper{
    height: max-content;
    display: flex;
    background-color: $white;
    border-radius: 5px;
    flex-direction: column;
    min-width: 975px;
    width: 100%;
    .detailLink{
      cursor: pointer;
    }
    @include themify($themes){
      font-family: themed(font-family);
    }

    .operationBtn{
      border-radius: 5px;
      padding: 7px 15px;
      line-height: 1;
      white-space: nowrap;
      cursor: pointer;
      color: #FFF;
      &.disabledBtn{
        background-color: #ddd;
        pointer-events: none;
      }

      &.editAndSave{
          @include themify($themes){
              background: themed(theme-primary-color);
              border-color:themed(theme-primary-color);
            }
          &:hover{
              @include themify($themes){
                  background: themed(theme-secondary-color);
                  border-color:themed(theme-secondary-color);
                }
          }
      }

      &.add{
          background-color: #67C23A;
          border-color: #67C23A;
          &:hover{
              background: #85ce61 !important;
              border-color: #85ce61 !important;
          }
      }
      &.remove{
          background-color: #F56C6C;
          border-color: #F56C6C;
          &:hover{
              background: #f78989 !important;
              border-color: #f78989 !important;
          }
      }
    }

    .headerStyle{
      white-space: nowrap !important;
    }

    .successValue{
      color: #80c269;
    }
    .negativeValue{
      color: #f70824;
    }

    .filterWrapper {
        font-size: 12px;
        display: flex;
        width: 100%;
        @include themify($themes){
          border: 1px solid themed(table-border-color);
        }
        .leftPart{
          width: 95%;
          @include themify($themes){
            border-right: 1px solid themed(table-border-color);
          }
          &>div{
            display: flex;
            width: 100%;
            align-items: center;
            padding: 5px 10px;
            &>div{
              display: flex;
              align-items: center;
              white-space: nowrap;
            }
          }

          &>div:nth-of-type(1){
            @include themify($themes){
              border-bottom: 1px solid themed(table-border-color);
            }
          }
        }
        .rightPart{
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          &>div{
            cursor: pointer;
          }
        }

        .el-input{
          width: 100px;
          margin-left: 5px;
        }

        .el-input__inner{
          height: 30px !important;
        }
   
        .el-select-dropdown__item.selected,
        .el-select-dropdown__item.selected.hover {
          font-weight: 700;
          @include themify($themes){
            color: themed(theme-primary-color);
            background-color: themed(drop-down-hover);
          }
        }

        .el-select {
          .el-input__inner:hover, .el-input__inner:focus {
            @include themify($themes){
              border-color: themed(theme-primary-color);  
              background-color: themed(drop-down-hover);
            }
          }
          .el-input__inner {      
            width: 100px;
            padding-right: 10px;
            .is-active {
            @include themify($themes){
              color: themed(theme-primary-color);
              border-color:themed(theme-primary-color);
            }
          }
          }   
        }  
        .el-select-dropdown__item:hover {
          @include themify($themes){
            background-color: themed(drop-down-hover);
          }
        }
        .el-select-dropdown {
          border-radius: 4px !important;
        }
        .betQueryDatepicker {
          .el-input {
            width: 220px !important;
          }
        }
        .searchButton {
          color: $white;
          font-weight: 700;
          @include themify($themes) {
            background: themed(search-button-color);
            border: 1px solid themed(search-button-color);
          }
          border-radius: 5px;
          outline: none;
          font-size: 12px;
          position: relative;
          padding: 5px 15px 5px 15px;
          box-shadow: none;
          &:hover {
            @include themify($themes) {
              background: themed(search-button-color);
              border: 1px solid themed(search-button-color);
            }
          }
        }
      }
}