@import '../../../scss/web_base';

.ant-message{
  z-index: 1100 !important;
}
.thirdGamesAccountModalWrapper {
  border-radius: 5px;
  width: 850px;
  @include themify($themes) {
    font-family: themed(font-family);
  }
  .modalHeader {
    line-height: 34px;
    color: $white;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
      font-family: themed(font-family);
    }
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    .closeIcon {
      cursor: pointer;
    }
  }
  .drawhalfbox {
    @include themify($themes){
        border-left: 1px solid themed(table-border-color);
        border-right: 1px solid themed(table-border-color);
        border-bottom: 1px solid themed(table-border-color);
      }
  }
  .modalContent {
    width: 100% !important;
    background-color: white;
    font-size: 12px;
    margin: 0;
    max-height: 500px;
    padding-bottom: 2em;
    color: #5d6d7b;
    border:none;
    @include themify($themes) {
      font-family: themed(font-family);
    }
    .confirmText{
      text-align: center;
    }
    .exclamationIcon {
      height: 7em;
      width: 7em;
      circle{
        @include themify($themes){
          fill: themed(theme-secondary-color) !important;
        }
      }
    }
    .successText {
      @include themify($themes) {
        color: themed(success-modal-text-color)
      }
    }
  }
  .tableWrapper {
    max-height: 400px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(212, 212, 212);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgb(204, 204, 204);
    }
  }
  .keyButton {
    padding: 2px;
    width: 120px;
    height: 25px;
    color: $white;
    border-radius: 4px;
    font-size: 12px;
    text-align: center;
    @include themify($themes){
      background-color: themed(button-color);
      border: 1px solid themed(button-color);
    }
  }
  .modalFooter {
    font-size: 16px;
    margin: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    @include themify($themes) {
      background-color:themed(theme-third-color);
    }
    .confirmButton {
      width: 120px;
      height: 36px;
      color: $white;
      border-radius: 4px;
      font-size: 16px;
      text-align: center;
      @include themify($themes){
        background-color: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
    }
  }
}
  .el-message {
    z-index: 9999 !important;
    top: 24%;
    background: #EAF3FF;
    @include themify($themes){
      border: 1px solid themed(theme-third-color);
    }
    margin-left: 0;
  }
  .el-message__img {
    width: 0;
    height: 0;
  }
  .el-message__group p {
    color: #5A6167;
  }
  .el-message-box {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  }
  .v-modal {
    z-index: 2000 !important;
  }

