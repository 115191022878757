@import '../../scss/web_base';

.transactionManagementWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  .betQueryWrapper{
    padding: 0px !important;
  }
  .headerStyle{
    white-space: nowrap !important;
  }
  tr{
    height: 49px;
    em{
      color: red !important;
      font-weight: 600;
    }
  }
  
}