@import '../../../scss/_web_base';

.userProfileWrapper{
    font-size: 12px;
    font-size: 12px;
    color: #5d6d7b;
    position: relative;
    .noWrapStyle{
      white-space: nowrap;
    }

    .menuRow{
        padding: 5px 10px;
        width: 100%;
        display: flex;
        @include themify($themes){
          background-color: themed(theme-third-color);
        }
        .currentSelected{
            border: 1px solid #d2d7e0;
            border-radius: 3px;
            cursor: pointer;
            position: relative;
            width: 120px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            padding: 0;
            background-color: white;
            @include themify($themes){
              color: themed(theme-primary-color);
            }
            &:hover{
              @include themify($themes){
                background-color: themed(theme-primary-color) !important;
              }
                color: white !important;
                .arrow_down{
                    border-top: 5px solid white !important;
                }
            }
            .arrow_down{
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                display: inline-block;
                margin: 2px 10px;
                @include themify($themes){
                  border-top: 5px solid themed(theme-primary-color);
                }
            }
            .userProfileMenuWrapper{
                position: absolute;
                z-index: 1;
            }
        }
    }

    .userProfileTableWrapper{
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      margin: 10px 0;
      border-radius: 5px;
      border: 1px solid #ddd;
      background: #ffffff;

      .userTable{
        border: 1px solid #ddd;

        .mytooltip {
          position: relative;
          display: inline-block;
        }
        
        .mytooltip .tooltiptext {
          visibility: hidden;
          width: 140px;
          background-color: #555;
          color: #fff;
          text-align: center;
          border-radius: 6px;
          padding: 5px;
          position: absolute;
          z-index: 1;
          bottom: 150%;
          left: 50%;
          margin-left: -75px;
          opacity: 0;
          transition: opacity 0.3s;
        }
        
        .mytooltip .tooltiptext::after {
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: #555 transparent transparent transparent;
        }
        
        .mytooltip:hover .tooltiptext {
          visibility: visible;
          opacity: 1;
        }
        .confirmButton {
          cursor: pointer;
          width: 54px;
          height: 28px;
          margin: 0px 5px;
          &.generateBtn{
              @include themify($themes){
                  background: themed(theme-primary-color);
                  border-color:themed(theme-primary-color);
                }
              &:hover{
                  @include themify($themes){
                      background: themed(theme-secondary-color);
                      border-color:themed(theme-secondary-color);
                    }
              }
          }
          &.copyBtn{
            background-color: #E6A23C;
            border-color: #E6A23C;
            &:hover{
                background: #ebb563 !important;
                border-color: #ebb563 !important;
            }
          }
          color: $white;
          border-radius: 4px;
          font-size: 12px;
          text-align: center;
          line-height: 28px;
        }
  
        .textBox{
          height: auto;
          width: 50% !important;
          padding: 2px;
          overflow-wrap: break-word;    
          border-radius: 3px;
          @include themify($themes){
              border: 1px solid themed(table-border-color);
            }
        }
        &>div{
          min-height: 40px;
        }
        &>div:first-child{
          @include themify($themes){
            background-color: themed(theme-third-color);
          }
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &>div:not(:first-child){
          border-top: 1px solid #ddd;
          &>div:first-child{
            width: 20%;
            border-right: 1px solid #ddd;
            @include themify($themes){
              background-color: themed(theme-third-color);
            }
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &>div:last-child{
            width: 80%;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            .text-danger{
              flex: 1;
            }
          }
        }
      }
      th,td{
        height: 40.5px;
      }
      .tableBodyRow {
        &>td:first-child{
          @include themify($themes){
            background-color: themed(theme-third-color);
          }
        width: 150px !important;
        }
      }
    }
}