@import '../../../scss/web_base';

.InstallmentManagementWrapper{

  .resultNumCell{
    width: 80px !important;
  }
  .qishuInputStyle{
    width: 216px;
  }

  .orderInformationTable{
    border: 1px solid #ddd;
    .evenRowStyle{
        display: flex;
        &>div:nth-child(odd){
          @include themify($themes){
            background-color: themed(theme-third-color);
          }
            width: 25%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 20px;
        }
        &>div:nth-child(even){
            flex: 1;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 20px;
        }
        &>div:not(:last-child){
            border-right: 1px solid #ddd;
        }
    }
    &>div{
        min-height: 40px;
    }
    &>div:not(:last-child){
        border-bottom: 1px solid #ddd;
    }
    .orderInformationRowBackGroundColor{
      @include themify($themes){
        background-color: themed(theme-third-color);
      }
    }
}

  .mousePointIn{
    cursor: pointer;
    .shiftBtn{
      display: none;
    }
    .statusBtn{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 55px;
    }
    &:hover{
      .statusBtn{
        display: none;
      }
      .shiftBtn{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFF;
        border-radius: 5px;
        width: 55px;
        padding: 3px 0px;
        @include themify($themes){
          background: themed(theme-primary-color);
          border-color:themed(theme-primary-color);
        }
      }
    }
  }
  .operationBtn{
    border-radius: 5px;
    padding: 6px 10px;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #FFF;
    @include themify($themes){
      background: themed(theme-primary-color);
      border-color:themed(theme-primary-color);
    }
    &:hover{
        @include themify($themes){
            background: themed(theme-secondary-color);
            border-color:themed(theme-secondary-color);
          }
    }

    &.editAndSave{
      @include themify($themes){
          background: themed(theme-primary-color);
          border-color:themed(theme-primary-color);
        }
      &:hover{
          @include themify($themes){
              background: themed(theme-secondary-color);
              border-color:themed(theme-secondary-color);
            }
      }
  }

    &.find{
      background-color: #F56C6C;
      border-color: #F56C6C;
      &:hover{
          background: #f78989 !important;
          border-color: #f78989 !important;
      }
      .plus_icon{
        margin-right: 3px;
        border-radius: 50%;
        padding: 0px 2px;
        display: inline;
        background-color: white !important;
      .el-icon-plus{
        color: #F56C6C;
      }
    }
    }
  }
    color: #5d6d7b;
    height: max-content;
    display: flex;
    background-color: $white;
    border-radius: 5px;
    flex-direction: column;
    width: 100%;
    @include themify($themes){
      font-family: themed(font-family);
    }
    .menuSelectedText {
      width: 90px !important;
    }
    .subselection {
      position:absolute;
      top:5;
      z-index:1;
    }
    .hide {
      display: none;
    }
    .menuWrapper {
      background: white;
      height: 30px;
      width: 120px !important;
      font-size: 12px;
      text-align: left;
      border-radius: 3px;
      padding: 5px 10px 5px 10px;
      color:black;
    }

    .filterWrapper {
      color: #5d6d7b;
      font-size: 12px;
      border-radius: 3px;
      border: 1px solid #dcdcdc;
        @include themify($themes){
            background-color: themed(theme-third-color);
        }
      }
}