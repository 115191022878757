@import '~antd/dist/antd.css';
html,
body,
#root {
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  .colorSelectBox{
    width: 26px;
    height: 26px;
    border-radius: 3px;
    margin: 0px 3px;
    cursor: pointer;
    &.blue{
      background-color: #448eed;
    }
    &.red{
      background-color: #b54358;
    }
    &.green{
      background-color: #60990a;
    }
    &.yellow{
      background-color: #d79a18;
    }
    circle{
      fill: transparent !important;
    }
  }
  table{
    height: 0px !important;
  }
  .ant-select{
    font-size: 12px !important;
    .ant-select-selector {
      padding: 0 10px !important;
  }
  }
}