@import '../../scss/_web_base';  

.modal{
  position: fixed !important;
  margin: auto !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 1055 !important;
  outline: 0 !important;
  height: 100% !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  overflow: hidden !important;
}
.fade {
  transition: opacity .4s ease-out !important;
}
@keyframes popUpShow {
  0% {
    opacity: 0.3 !important;
    -webkit-transform: translateY(150px);
    -moz-transform: translateY(150px);
    -ms-transform: translateY(150px);
    -o-transform: translateY(150px);
    transform: translateY(150px);
  }
  60% {
    opacity: 0.9 !important;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
  100% {
    opacity: 1 !important;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes popUpHide {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  60% {
    opacity: 0.9;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  100% {
    opacity: 0.3;
    -webkit-transform: translateY(100px);
    -moz-transform: translateY(100px);
    -ms-transform: translateY(100px);
    -o-transform: translateY(100px);
    transform: translateY(100px);
  }
}
.popUpOpen {
  animation: popUpShow 900ms ease-out 1 forwards !important;
}
.popUpClose{
  animation: popUpHide 400ms ease-out 1 forwards !important;
}

.modal-dialog {
  display: flex;
  justify-content: center;
  .modal-content {
    border-radius: 6px;
    width: auto !important;
    border: none;
  }
}