@import '../../../scss/web_base';

.createPageSettingModalWrapperWeb {
  width: 550px;
  @include themify($themes) {
    font-family: themed(font-family);
  }
  .modalHeader {
    line-height: 45px;
    color: $white;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
      font-family: themed(font-family);
    }
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
    .closeIcon {
      cursor: pointer;
    }
  }
  .modalContent {
    font-size: 16px;
    height: max-content;
    margin: 0;
    padding: 15px;
    &>div{
      border-top: 1px solid #dcdcdc;
      border-left: 1px solid #dcdcdc;
      border-right: 1px solid #dcdcdc;
      &>div:first-child{
        border-right: 1px solid #dcdcdc;
      }
    }
    &>div:last-child{
      border-bottom: 1px solid #dcdcdc;
    }

    &>div{
      display: flex;
      &>div{
        padding: 5px;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .modalFooter {
    font-size: 16px;
    height: max-content;
    margin: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include themify($themes) {
      background-color:themed(theme-third-color);
    }
    @include themify($themes) {
      border-top: 1px solid #dcdcdc
    }
    .confirmButton {
      width: 120px;
      height: 36px;
      color: $white;
      border-radius: 4px;
      font-size: 16px;
      text-align: center;
      @include themify($themes){
        background-color: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
    }
    .cancelButton {
      width: 120px;
      height: 36px;
      border-radius: 4px;
      font-size: 16px;
      text-align: center;
      background-color: $white;
      @include themify($themes){
        color: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
      &:hover {
        background-color: $white;
      }
    }
  }
}
