@import '../../scss/web_base';

.displayLabelWrapper {
display: flex;
justify-content: center;
align-items: center;
@include themify($themes){
    background-color: themed(theme-third-color);
  }

  .pk10Square{
    font-weight: bold;
    font-size: 12px;
    width: 22px;
    height: 22px;
    color: white;
    border-radius: 5px;
    border:3px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.square_1{
      @include themify($themes){
        background-color: themed(table-square-1);
      }
    }
    &.square_2{
      @include themify($themes){
        background-color: themed(table-square-2);
      }
    }
    &.square_3{
      @include themify($themes){
        background-color: themed(table-square-3);
      }
    }
    &.square_4{
      @include themify($themes){
        background-color: themed(table-square-4);
      }
    }
    &.square_5{
      @include themify($themes){
        background-color: themed(table-square-5);
      }
    }
    &.square_6{
      @include themify($themes){
        background-color: themed(table-square-6);
      }
    }
    &.square_7{
      @include themify($themes){
        background-color: themed(table-square-7);
      }
    }
    &.square_8{
      @include themify($themes){
        background-color: themed(table-square-8);
      }
    }
    &.square_9{
      @include themify($themes){
        background-color: themed(table-square-9);
      }
    }
    &.square_10{
      @include themify($themes){
        background-color: themed(table-square-10);
      }
    }

  }
  .hk6Ball{
    width: 25px;
    height: 25px;
    line-height: 19px;
    text-align: center;
    box-sizing: border-box;
    color: #626262;
    font-size: 10px;
    font-weight: bold;
    border-radius: 50%;
    border: 3px solid;
    padding: 0;

    &.ball_red{
        border-color: $ballRed !important;
    }
    &.ball_blue{
        border-color: $ballBlue !important;
    }
    &.ball_green{
        border-color: $ballGreen !important;
    }
  }
  .hk6_sebo{
    width: 100%;
    height: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    &.redbo{
      background-color: $ballRed;
    }
    &.bluebo{
      background-color: $ballBlue;
    }
    &.greenbo{
      background-color: $ballGreen;
    }

  }
}