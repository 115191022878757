@import '../../scss/web_base';

.restockOperationLogWrapper {
    height: max-content;
    display: flex;
    background-color: $white;
    border-radius: 5px;
    flex-direction: column;
    min-width: 975px;
    width: 100%;
    font-size: 12px;
    .radiobuttongroup{
      *{
        cursor: pointer;
      }
    }
    @include themify($themes){
      font-family: themed(font-family);
    }
    .notice {
        font-size: 12px;
        @include themify($themes){
            color: themed(notice-text-color);
          }
    }
}