@import '../../../scss/web_base';

.memberListWrapper{
    height: max-content;
    display: flex;
    background-color: $white;
    border-radius: 5px;
    flex-direction: column;
    min-width: 975px;
    width: 100%;
    @include themify($themes){
      font-family: themed(font-family);
    }
    .menuSelectedText {
      width: 90px !important;
    }
    .smallicon {
      font-size: 10px;
    }
    .subselection {
      position:absolute;
      top:5;
      z-index:1;
    }
    .el-popover {
      width: 180px !important;
      padding: 7px;
    }
    .hide {
      display: none;
    }
    .marginLeft5{
      margin-left: 1.2em;
    }
    .menuWrapper {
      background: white;
      height: 30px;
      width: 120px !important;
      font-size: 12px;
      text-align: left;
      border-radius: 3px;
      padding: 5px 10px 5px 10px;
      color:black;
    }
    .bellNoti{
      font-size: 1rem;
      line-height: 0;
    }
    .bellNotiCircle{
      font-size: 1rem;
      line-height: 0;
      @include themify($themes){
        background-color: themed(notice-color);
      }
      padding: 7px;
      border-radius: 50%;
      display:block;
    }
    .subMenu  {
      border: 1px solid rgb(220,220,220);
      border-radius: 3px;
      width: 100%;
      padding: 8px 20px;
      @include themify($themes){
        border: 1px solid themed(table-border-color);
        background-color: themed(theme-third-color);
      }
    }
    .addButton {
      color: $white;
      font-weight: 300;
      @include themify($themes) {
        background: themed(menu-amount);
        border: 1px solid themed(menu-amount);
      }
      border-radius: 5px;
      outline: none;
      font-size: 11.4px;
      position: relative;
      padding: 2px 9px 2px 5px;
      box-shadow: none;
      &:hover {
        @include themify($themes) {
          background: themed(menu-amount);
          border: 1px solid themed(menu-amount);
        }
      }
    }

    .filterWrapper {
        color:white;
        font-size: 12px;
        border-radius: 3px;
        border: 1px solid #dcdcdc;
        @include themify($themes){
            background-color: themed(theme-primary-color);
        }
        input {
          height: 30px;
          width: 95px !important;
          font-size: 12px;
          padding: 5px 8px 5px 8px;
        }
        input[type=number] {
          width: 60px !important;
        }
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type=number] {
          -moz-appearance: textfield;
        }
        .form-control {
          width: 17%;
        }
   
        .el-select-dropdown__item.selected,
        .el-select-dropdown__item.selected.hover {
          font-weight: 700;
          @include themify($themes){
            color: themed(theme-primary-color);
            background-color: themed(drop-down-hover);
          }
        }

        .el-select {
          .el-input__inner:hover, .el-input__inner:focus {
            @include themify($themes){
              border-color: themed(theme-primary-color);  
              background-color: themed(drop-down-hover);
            }
          }
          .el-input__inner {      
            width: 115px;
            padding-right: 10px;
            .is-active {
            @include themify($themes){
              color: themed(theme-primary-color);
              border-color:themed(theme-primary-color);
            }
          }
          }   
        }  
        .el-select-dropdown__item:hover {
          @include themify($themes){
            background-color: themed(drop-down-hover);
          }
        }
        .el-select-dropdown {
          border-radius: 4px !important;
        }
        .betQueryDatepicker {
          width: 185px !important;
          .el-input__inner {
            width: 190px !important;
          }
          .el-date-editor.el-input {
            width: 190px !important;
          }
        }
        .searchButton {
          color: $white;
          font-weight: 700;
          @include themify($themes) {
            background: themed(search-button-color);
            border: 1px solid themed(search-button-color);
          }
          border-radius: 5px;
          outline: none;
          font-size: 12px;
          position: relative;
          padding: 5px 15px 5px 15px;
          box-shadow: none;
          &:hover {
            @include themify($themes) {
              background: themed(search-button-color);
              border: 1px solid themed(search-button-color);
            }
          }
        }
      }
      .popButton {
        .btn {
          color: $white;
          border-radius: 2px;
          outline: none;
          font-size: 11.5px !important;
          position: relative;
          padding: 1px 9px 1px 9px !important;
          box-shadow: none;
        }
      
        .idle {
          @include themify($themes){
            border: 1px solid themed(theme-primary-color);
            background-color: themed(theme-primary-color);
          }
        }
        .active {
          @include themify($themes){
            border: 1px solid themed(table-border-color);
            background-color: themed(table-border-color);
          }
        }
      }
}