@import '../../../scss/_web_base';


.BetWarningMonitorWrapper {
  font-size: 12px;
  color: #5d6d7b;
  position: relative;

  .pl-2 {
    padding-left: 10;
  }

  .lotteryList {
    @include themify($themes) {
      border: 1px solid themed(table-border-color);
    }
    font-weight: bold;
    // max-width: 1380px;
    min-width: 975px; // same min width as filter wrapper in this file 
    background-color: #ffeee3;
    display: flex;
    align-items: center;
    overflow-x: scroll;
    padding-top: 10px;
    padding-bottom: 10px;

    .lotteryItem {
      text-align: center;
      cursor: pointer;
      padding: 10px;
      min-width: max-content;

      &.active {
        @include themify($themes) {
          border: 1px solid themed(table-border-color);
        }
        background-color: white;
      }
    }

  }

  .oddHeader {
    border-collapse: collapse;
    font-size: 12px;
    padding: 7px;
    text-align: center;
    font-weight: 600;

    @include themify($themes) {
      border-top: 1px solid themed(table-border-color);
      border-left: 1px solid themed(table-border-color);
      border-right: 1px solid themed(table-border-color);
      background-color: themed(theme-third-color);
      color: themed(table-header-text-color);
    }

    &.drawbottom {
      @include themify($themes) {
        border-bottom: 1px solid themed(table-border-color);
      }
    }
  }

  .refreshIcon {
    width: 30px;
  }

  .filterWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .refreshButton {
    text-align: center;
    border-radius: 5px;
    height: 30px;
    width: 55px;
    line-height: 30px;
    color: white;
    cursor: pointer;

    @include themify($themes) {
      background-color: themed(search-button-color);
    }

    &.select {
      background-color: #67C23A;
    }

    &.deselect {
      background-color: #E6A23C;
    }
  }

  .info_tishi {
    color: #808080
  }

  .minxi {
    color: '#0000ff';
    cursor: pointer;

    &:hover {
      color: red;
    }
  }

  .marginLeft1 {
    margin-left: 4px;
  }

  .betWarningInput {
    width: 50%;
    max-width: 120px;
  }

  // copy from UserManagement component AgentList className： DirectAgentWrapper  
  .BetWarningMonitorFilterWrapper {
    height: max-content;
    display: flex;
    background-color: $white;
    border-radius: 5px;
    flex-direction: column;
    min-width: 975px;
    width: 100%;

    .headerName {
      color: white;
      font-size: 12px;
      font-weight: 600;
    }

    @include themify($themes) {
      font-family: themed(font-family);
    }

    .menuSelectedText {
      width: 90px !important;
    }

    .smallicon {
      font-size: 10px;
    }

    .subselection {
      position: absolute;
      top: 5;
      z-index: 1;
    }

    .hide {
      display: none;
    }

    .marginLeft5 {
      margin-left: 1.2em;
    }

    .menuWrapper {
      background: white;
      height: 30px;
      width: 120px !important;
      font-size: 12px;
      text-align: left;
      border-radius: 3px;
      padding: 5px 10px 5px 10px;
      color: black;
    }

    .bellNoti {
      font-size: 1rem;
      line-height: 0;
    }

    .bellNotiCircle {
      font-size: 1rem;
      line-height: 0;

      @include themify($themes) {
        background-color: themed(notice-color);
      }

      padding: 7px;
      border-radius: 50%;
      display:block;
    }

    .subMenu {
      border: 1px solid rgb(220, 220, 220);
      border-radius: 3px;
      width: 100%;
      padding: 8px 20px;

      @include themify($themes) {
        border: 1px solid themed(table-border-color);
        background-color: themed(theme-third-color);
      }
    }

    .addButton {
      color: $white;
      font-weight: 300;

      @include themify($themes) {
        background: themed(menu-amount);
        border: 1px solid themed(menu-amount);
      }

      border-radius: 5px;
      outline: none;
      font-size: 11.4px;
      position: relative;
      padding: 2px 9px 2px 5px;
      box-shadow: none;

      &:hover {
        @include themify($themes) {
          background: themed(menu-amount);
          border: 1px solid themed(menu-amount);
        }
      }
    }

    .filterWrapper {
      color: white;
      font-size: 12px;
      border-radius: 3px;
      border: 1px solid #dcdcdc;

      @include themify($themes) {
        background-color: themed(theme-primary-color);
      }

      // input {
      //   height: 30px;
      //   width: 95px !important;
      //   font-size: 12px;
      //   padding: 5px 8px 5px 8px;
      // }

      // input[type=number] { 12=20
      //   width: 60px !important;
      // }

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type=number] {
        -moz-appearance: textfield;
      }

      .form-control {
        width: 17%;
      }

      .betQueryDatepicker {
        width: 185px !important;
      }

    }
  }
}