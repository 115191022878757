@import '../../../scss//web_base';

.historyTaskWrapper {
  position: relative;
  width: 100%;

  .menuWrapper {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    .menuItem{
      border-bottom: 1px solid transparent;
    &.selected,&:hover{
      @include themify($themes){
        color:themed(theme-primary-color);
        border-bottom: 2px solid themed(theme-primary-color);
      }
    }
  }
  }

  .operationBtn{
    border-radius: 5px;
    padding: 7px 10px;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    color: #FFF;
    @include themify($themes){
      background: themed(theme-primary-color);
      border-color:themed(theme-primary-color);
    }
    &:hover{
        @include themify($themes){
            background: themed(theme-secondary-color);
            border-color:themed(theme-secondary-color);
          }
    }

    &.editAndSave{
      @include themify($themes){
          background: themed(theme-primary-color);
          border-color:themed(theme-primary-color);
        }
      &:hover{
          @include themify($themes){
              background: themed(theme-secondary-color);
              border-color:themed(theme-secondary-color);
            }
      }
  }
  &.cancel, &.editAndSave{
    padding: 10px 15px !important;
  }

    &.add, &.cancel{
      background-color: #F56C6C;
      border-color: #F56C6C;
      &:hover{
          background: #f78989 !important;
          border-color: #f78989 !important;
      }
      .plus_icon{
        margin-right: 3px;
        border-radius: 50%;
        padding: 0px 2px;
        display: inline;
        background-color: white !important;
      .el-icon-plus{
        color: #F56C6C;
      }
    }
    }
  }
}