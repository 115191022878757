@import '../../../scss/_web_base';

.SystemSettingWrapper {
    width: 100%;
    @include themify($themes){
      font-family: themed(font-family);
    }
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-bottom: 10px;
    .confirmRow{
      height: 42px;
      width: 100%;
      justify-content: center;
      align-items: center;
      .confirmButton {
        cursor: pointer;
        width: 84px;
        height: 28px;
        margin: 0px 5px;
        &.confirm{
            @include themify($themes){
                background: themed(theme-primary-color);
                border-color:themed(theme-primary-color);
              }
            &:hover{
                @include themify($themes){
                    background: themed(theme-secondary-color);
                    border-color:themed(theme-secondary-color);
                  }
            }
        }
        &.reset{
            background-color: #F56C6C;
            border-color: #F56C6C;
            &:hover{
                background: #f78989 !important;
                border-color: #f78989 !important;
            }
        }
        color: $white;
        border-radius: 4px;
        font-size: 12px;
        text-align: center;
        line-height: 28px;
      }
    }
    .thirdPartyGamesTable{
        .thirdPartyGamesTitle{
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          @include themify($themes){
            background: themed(theme-third-color);
          }
        }
        @include themify($themes) {
        border: 1px solid themed(table-border-color);
        }
        &>div:not(:last-child){
          @include themify($themes) {
          border-bottom: 1px solid themed(table-border-color);
          }
        }
        .thirdPartyGamesRow{
          display: flex;
          width: 100%;
          &>div{
            padding: 5px 10px;
          }
          &>div:last-child{
            flex: 1;
          }
          &>div:first-child{
            @include themify($themes){
              background: themed(theme-third-color);
            }
            width: 25%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            @include themify($themes) {
            border-right: 1px solid themed(table-border-color);  
            }  
          }
          .domain.el-input{
            width: 50%;
          }
    
          .el-input__inner{
            height: 30px !important;
        }
    
          .el-select-dropdown__item.selected,
        .el-select-dropdown__item.selected.hover {
          font-weight: 700;
          @include themify($themes){
            color: themed(theme-primary-color);
            background-color: themed(drop-down-hover);
          }
        }
    
        .el-select {
          .el-input__inner:hover, .el-input__inner:focus {
            @include themify($themes){
              border-color: themed(theme-primary-color);  
              background-color: themed(drop-down-hover);
            }
          }
          .el-input__inner {      
            height: 30px !important;
            padding-right: 10px;
            .is-active {
            @include themify($themes){
              color: themed(theme-primary-color);
              border-color:themed(theme-primary-color);
            }
          }
          }   
        }  
        .el-select-dropdown__item:hover {
          @include themify($themes){
            background-color: themed(drop-down-hover);
          }
        }
        .el-select-dropdown {
          border-radius: 4px !important;
        }
        }
    
      }
  }