@import "../../../scss/web_base";


.authHeader {
  @include themify($themes) {
    background-color: themed(theme-primary-color);
  }
  transition: all 300ms ease 0s;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 1440px;
  .headerLogo {
    justify-content: center !important;
    width: 180px !important;
  }
  .headerTopMenu {
    width: max-content;
    flex: 1;
  }
  .headerToggleButton {
    align-items: center;
    width: 250px !important;
    display: flex;
    padding-right: 10px;
    &>div:first-child{
      flex: 1;
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .headerNameAndTime{
        position: relative;
        width: 100%;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        &:hover{
          .onlineMessageWrapper{
            display: block !important;
          }
        }
        .onlineMessageWrapper{
          display: none;
          position: fixed;
          z-index: 1;
          padding-top: 60px;
          .onlineMessage{
            color: black;
            padding: 8px 16px;
            border: 1px solid #dcdcdc;
            background: #fff;
            &>div{
              display: flex;
              align-items: center;
              padding: 5px 0px;
            }
            &>div:first-child{
              border-bottom: 1px solid #ddd;
            }
          }
        }
      }
    }
  }
  @keyframes heighton {
    from {
      padding: 0px;
      height: 0px;
      overflow-y: hidden;
    }
    to {
      padding: 0.5rem 0rem;
      height: 66px;
      overflow-y: visible;
    }
  }

  @keyframes heightoff {
    from {
      padding: 0.5rem 0rem;
      height: 66px;
      overflow-y: hidden;
    }
    to {
      padding: 0px;
      height: 0px;
      overflow-y: hidden;
    }
  }
  .gameResultMenuDefault{
    height: 69px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .gameResultMenuOn {
    animation-name: heighton;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }
  .gameResultMenuOff {
    animation-name: heightoff;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }
  .gameList {
    width: 100%;
    justify-content: flex-start;
    display: flex;
    font-size: 12px;
    padding-left: 10px;
    @include themify($themes) {
      background-color: themed(header-selected-menu);
      color: themed(sub-game-menu-text-color);
    }
    .currentLotteryValueWrapper {
      cursor: pointer;
      position: relative;
      font-size: 13px;
      margin-left: 15px;
      width: 120px;
      @include themify($themes) {
        color: themed(sub-game-active-game-text-color);
      }
      .forwardIcon {
        margin-left: 5px;
      }
      .headerLotteryMenuWrapper{
        position: fixed;
        z-index: 1;
        padding-top: 12px;
      }
    }
    .menuWrapper {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      border-left: 1px solid $border;
      padding-left: 5px;
      .menuItem{
        padding-left: 5px;
        padding-right: 5px;
        font-size: 11px;
        border-bottom: 1px solid transparent;
      &.selected,&:hover{
        @include themify($themes){
          color:themed(theme-primary-color);
          border-bottom: 2px solid themed(theme-primary-color);
        }
      }
    }
    }
  }
  .gameListOpen {
    overflow-y: hidden;
    height: 43px !important;
    transition: height 0.5s linear;
  }
  .gameListClose {
    overflow-y: hidden;
    height: 0 !important;
    transition: height 0.5s linear;
  }
  .themeSelector{
    width: 20px;
    margin-right: 10px;
    svg:hover{
      cursor: pointer;
      path{
        fill: yellow !important;
      }
    }
    .themeColorSelector{
      top: 45px !important;
    }
  }
  .switchBar {
    cursor: pointer;
    position: relative;
    width: 3rem;
    .switchBox {
      background-color: transparent;
      height: 1.5rem;
    }
    .switchLeft {
      position: absolute;
      top: 0px;
      width: 1.5rem;
      height: 1.5rem;
      left: 0px;
    }
    .switchToLeft {
      left: 0 !important;
      transition: left 0.5s;
    }
    .switchRight {
      position: absolute;
      top: 0px;
      width: 1.5rem;
      height: 1.5rem;
      left: calc(100% - 1.5rem);
    }
    .switchToRight {
      left: calc(100% - 1.5rem) !important;
      transition: left 0.5s;
    }
  }
}
