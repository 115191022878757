@import '../../../scss//web_base';

.PublishAnnoucementWrapper {
  position: relative;

  .lotterys{
    flex-wrap: wrap;
  }
  .text-max{
    min-width: max-content;
  }
  .noWrapStyle{
    white-space: nowrap;
    padding: 0px 20px;
  }
  .oldValueStyle,.newValueStyle{
    max-width: 300px;
    text-align: left;
  }
  .form-check-input[type=checkbox] {
    border-radius: 0.15em;    }
  .el-input__inner {
    font-size: 12px;
    height: 30px;
  }
  .active {
    @include themify($themes){
      color: themed(theme-primary-color);
    }
  }
  .filterWrapper {
    color: #5d6d7b;
    font-size: 12px;
    border-radius: 3px;
    width: 100%;
    @include themify($themes){
      border: 1px solid themed(table-border-color);
      background-color: themed(theme-third-color);
    }
  }
  .button {
    color: $white;
    font-weight: 700;
    border-radius: 5px;
    outline: none;
    font-size: 12px;
    position: relative;
    padding: 3px 15px 3px 15px;
    box-shadow: none;
    &.find{
      background: #f56f62;
      border: 1px solid #f56f62;
    }
    &.handle{
      background-color: #409EFF;
      border-color: #409EFF;
    }
  }
  .bigWrapper {
    box-shadow: 0 1px 2px rgba(0,0,0,0.15);
    transition: box-shadow 0.3s ease-in-out;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: 100%;
  }
  input[type=text], input[type=number] {
    height: 30px;
    width: 120px;
    font-size: 12px;
    padding: 5px 8px 5px 8px;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
    width: 120px !important;
  }
  .textAreaInput {
    .form-control {
      font-size: 12px;
      min-width: 450px;
      max-width: 550px;
      min-height: 100px;
    }
  }
  .longInput {
    input[type=text], input[type=number] {
      height: 30px;
      width: 450px;
      font-size: 12px;
      padding: 5px 8px 5px 8px;
    }
  }
  .smallInput {
    input[type=text], input[type=number] {
      height: 30px;
      width: 80px;
      font-size: 12px;
      padding: 5px 8px 5px 8px;
    }
  }
  .smallLabel {
    min-width: 60px;
  }
  .titleWrapper {
    border-bottom: 1px solid #dcdcdc;
    color: #5d6d7b;
    font-size: 14px;
    font-weight: bold;
    padding: 15px 15px 15px 20px;
    background-color: #eaf3ff;
  }
  td,th{
    color: #5d6d7b;
    font-size: 12px;
    font-weight: normal;
    padding: 6px 8px;
    margin: 0 auto;
    @include themify($themes) {
      border: 1px solid themed(table-border-color);
    }
    vertical-align: middle;
  }
  .titleHeader{
    @include themify($themes){
      background-color: themed(theme-third-color);
      color: themed(table-header-text-color);
    }
    font-weight: normal;
    text-align: center;
  }
  .tableHeader{
    @include themify($themes){
      background-color: themed(theme-third-color);
      color: themed(table-header-text-color);
    }
    font-weight: normal;
    text-align: right;
  }
  tbody {
    tr {
      .topLeftBorderNone {
        border-top: none !important;
        border-left: none !important;
      }
    }
  }
  .oneCharCol {
    width: 50px;
  }
  th{
    font-weight: normal !important;
  }
}