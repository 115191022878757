@import '../../../scss/_web_base';

.PageSettingWrapper {
  position: relative;

  .menuManagementHeader{
    display: flex;
    align-items: center;
    word-break: keep-all;
    padding: 10px;
    @include themify($themes){
      background-color: themed(theme-third-color);
      border: 1px solid themed(table-border-color);  
    }
    .moduleSelect{
      width: 120px;
    }
    .menuNameInput{
      width: 200px;
    }
    .userRightSelect{
      width: 100px;
    }
  }

  .operationBtn{
    border-radius: 5px;
    padding: 7px 10px;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    color: #FFF;
    @include themify($themes){
      background: themed(theme-primary-color);
      border-color:themed(theme-primary-color);
    }
    &:hover{
        @include themify($themes){
            background: themed(theme-secondary-color);
            border-color:themed(theme-secondary-color);
          }
    }

    &.stopAndStart{
      background-color: #E6A23C;
      border-color: #E6A23C;
      &:hover{
          background: #ebb563 !important;
          border-color: #ebb563 !important;
      }
  }

    &.editAndSave{
      @include themify($themes){
          background: themed(theme-primary-color);
          border-color:themed(theme-primary-color);
        }
      &:hover{
          @include themify($themes){
              background: themed(theme-secondary-color);
              border-color:themed(theme-secondary-color);
            }
      }
  }

    &.add, &.cancel{
      background-color: #F56C6C;
      border-color: #F56C6C;
      &:hover{
          background: #f78989 !important;
          border-color: #f78989 !important;
      }
    }
  }

}