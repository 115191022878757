@import '../../../scss/web_base';

.copyOddsDiffModalWrapper {
  border-radius: 5px;
  width: 850px;
  @include themify($themes) {
    font-family: themed(font-family);
  }
  .modalHeader {
    line-height: 34px;
    color: $white;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
      font-family: themed(font-family);
    }
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    .closeIcon {
      cursor: pointer;
    }
  }

  .modalContent {
    .copoyDiffInputStyle{
      width: 200px;
    }

    .ant-checkbox-wrapper{
      width: 130px;
      margin: 5px 0px;
    }
    width: 100% !important;
    background-color: white;
    font-size: 12px;
    margin: 0;
    max-height: 500px;
    padding-bottom: 2em;
    color: #5d6d7b;
    border:none;
    @include themify($themes) {
      font-family: themed(font-family);
    }
    .confirmText{
      text-align: center;
    }
    .exclamationIcon {
      height: 7em;
      width: 7em;
      circle{
        @include themify($themes){
          fill: themed(theme-secondary-color) !important;
        }
      }
    }
    .successText {
      @include themify($themes) {
        color: themed(success-modal-text-color)
      }
    }
  }
  .loadingwrapper {
    background-color:#000;
    opacity: 0.3;
    position: absolute;
    padding-top: 30%;
    height: 100%;
    width: 100%;
    z-index: 5;
  }
  .tableWrapper {
    max-height: 400px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(212, 212, 212);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgb(204, 204, 204);
    }
  }
  .modalFooter {
    font-size: 16px;
    margin: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    @include themify($themes) {
      background-color:themed(theme-third-color);
    }
    .confirmButton {
      width: 120px;
      height: 36px;
      color: $white;
      border-radius: 4px;
      font-size: 16px;
      text-align: center;
      @include themify($themes){
        background-color: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
    }
  }
}

