@import '../../../../scss//web_base';

.AgentAddWrapper {
  position: relative;

  .text-center {
    text-align: center;
  }

  .borderRight {
    border-right: 1px solid #dddddd;
  }

  .borderLeft {
    border-left: 1px solid #dddddd;
  }

  .borderBottom {
    border-bottom: 1px solid #dddddd;
  }

  .text-red {
    color: red;
  }

  .rowHeader {
    min-height: 37px;
  }

  .bg-grey {
    background-color: #f9f9f9;
  }

  .w50 {
    width: 50% !important;
  }

  .w30 {
    width: 30% !important;
  }

  .w20 {
    width: 20% !important;
  }

  .w10 {
    width: 10% !important;
  }

  .ant-input {
    width: 200px;
  }

  .w-select-value {
    width: 200px;
  }

  .w-select {
    width: 120px;
  }

  .operationBtn {
    border-radius: 5px;
    padding: 7px 10px;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    color: #FFF;

    @include themify($themes) {
      background: themed(theme-primary-color);
      border-color: themed(theme-primary-color);
    }

    &:hover {
      @include themify($themes) {
        background: themed(theme-secondary-color);
        border-color: themed(theme-secondary-color);
      }
    }

    &.reset,
    &.cancel {
      background-color: #F56C6C;
      border-color: #F56C6C;

      &:hover {
        background: #f78989 !important;
        border-color: #f78989 !important;
      }

      .plus_icon {
        margin-right: 3px;
        border-radius: 50%;
        padding: 0px 2px;
        display: inline;
        background-color: white !important;

        .el-icon-plus {
          color: #F56C6C;
        }
      }
    }
  }

  .sectionWrapper {
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 15px 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ddd;
    background: #ffffff;

    .tableTitleStyle {
      height: 37px;
      width: 100%;
      border-top: 1px solid #ddd;
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;

      @include themify($themes) {
        background-color: themed(theme-third-color);
      }

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .tableSection {
      position: relative;
      border: 1px solid #ddd;
      min-width: 50%;
      width: 100%;

      &>div:not(:last-child) {
        border-bottom: 1px solid #ddd;
      }

      &.labelCellBackground {
        .rowStyle {
          &>div:first-child {
            @include themify($themes) {
              background-color: themed(theme-third-color);
            }
          }
        }
      }

      .rowStyle {
        position: relative;
        display: flex;
        min-height: 37px;
        &.zhanChengHeader{
          &>div{
            height: 74px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
          }
          &>div:not(:last-child){
            border-right: 1px solid #ddd;
          }
        }

        &.bg-grey {
          background-color: #f9f9f9;
        }

        &>div:first-child {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 20%;
          border-right: 1px solid #ddd;
          padding-right: 10px;
        }

        &>div:last-child {
          padding-left: 5px;
          display: flex;
          align-items: center;
          flex: 1;
          flex-wrap: wrap;
        }

        .lotterySelection {
          width: 130px;
          margin: 0px;
        }
      }

    }

  }
}