@import '../../scss/web_base';

.inTimeSheetItem {
display: flex;
@include themify($themes){
    border-top: 1px solid themed(table-border-color);
    }
&>div:not(:last-child){
    @include themify($themes){
        border-right: 1px solid themed(table-border-color);
        }
    }
&.selected{
    &>div{
        @include themify($themes){
            background-color: themed(in-time-selected) !important;
            }
        }
    }
}