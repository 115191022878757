@import '../../../scss/_web_base';


.BigBuhuoLimitWrapper {
  font-size: 12px;
  color: #5d6d7b;
  position: relative;

  .operationBtn {
    border-radius: 5px;
    padding: 7px 15px;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    color: #FFF;

    &.editAndSave {
      @include themify($themes) {
        background: themed(theme-primary-color);
        border-color: themed(theme-primary-color);
      }

      &:hover {
        @include themify($themes) {
          background: themed(theme-secondary-color);
          border-color: themed(theme-secondary-color);
        }
      }
    }

    &.add {
      background-color: #67C23A;
      border-color: #67C23A;

      &:hover {
        background: #85ce61 !important;
        border-color: #85ce61 !important;
      }
    }

    &.remove {
      background-color: #F56C6C;
      border-color: #F56C6C;

      &:hover {
        background: #f78989 !important;
        border-color: #f78989 !important;
      }
    }
  }

  .categorySelect {
    width: 140px;
  }

  .input {
    width: 100px;
  }

  .tableTimeSet {
    padding: 0;
    display: flex;
    align-items: center;

    .text {
      min-width: max-content;
    }
  }

  .button {
    color: $white;
    font-weight: 700;
    border-radius: 5px;
    outline: none;
    font-size: 12px;
    position: relative;
    padding: 5px 15px 5px 15px;
    box-shadow: none;

    &.save {
      background: #f56f62;
      border: 1px solid #f56f62;
    }

    &.cancel {
      background-color: #409EFF;
      border-color: #409EFF;
    }
  }

  .tabList {
    padding: 0px;
    display: flex;
    align-items: center;
    gap: 4px;

    .tabItem {
      min-width: 120px;
      cursor: pointer;
      color: white;
      padding: 5px 10px;

      @include themify($themes) {
        border: 1px solid themed(table-border-color);
        background-color: themed(theme-primary-color);

      }

      &.active {
        @include themify($themes) {
          border: 1px solid themed(table-border-color);
        }

        background-color: #de4521;
      }
    }
  }

  .pl-2 {
    padding-left: 10;
  }

  .lotteryList {
    @include themify($themes) {
      border: 1px solid themed(table-border-color);
    }

    font-weight: bold;
    // max-width: 1380px;
    min-width: 975px; // same min width as filter wrapper in this file 
    background-color: #ffeee3;
    display: flex;
    align-items: center;
    overflow-x: auto;
    padding-top: 10px;
    padding-bottom: 10px;

    .lotteryItem {
      text-align: center;
      cursor: pointer;
      padding: 10px;
      min-width: max-content;

      &.active {
        @include themify($themes) {
          border: 1px solid themed(table-border-color);
        }

        background-color: white;
      }
    }

  }

  .marginLeft1 {
    margin-left: 4px;
  }

}