@import '../../../scss/web_base';

.BalanceEditWrapperWeb {
  width: 550px;
  @include themify($themes) {
    font-family: themed(font-family);
  }
  td,
  th {
    color: #5d6d7b;
    font-size: 12px;
    font-weight: normal;
    padding: 6px 8px;
    margin: 0 auto;
    @include themify($themes) {
      border: 1px solid themed(table-border-color);
    }
    vertical-align: middle;
  }
  .tableHeader {
    @include themify($themes) {
      background-color: themed(theme-third-color);
      color: themed(table-header-text-color);
    }

    font-weight: normal;
    text-align: right;
    width: max-content;
  }

  tbody {
    tr {
      .topLeftBorderNone {
        border-top: none !important;
        border-left: none !important;
      }
    }
  }

  th {
    font-weight: normal !important;
  }

  .modalHeader {
    position: relative;
    line-height: 45px;
    color: $white;
    font-size: 16px;
    font-weight: bold;
    margin: 0;

    @include themify($themes) {
      background-color: themed(theme-primary-color);
      font-family: themed(font-family);
    }

    border-top-left-radius: 1px;
    border-top-right-radius: 1px;

    .closeIcon {
      cursor: pointer;
    }

    .icon {
      position: absolute;
      /* top: 0; */
      right: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
    }
  }

  .modalContent {
    padding: 12px;

    font-size: 16px;
    height: max-content;
    margin: 0;
    flex-direction: column;
    // padding-bottom: 2em;

    .confirmText {
      text-align: center;
    }

    .exclamationIcon {
      height: 7em;
      width: 7em;

      circle {
        @include themify($themes) {
          fill: themed(theme-secondary-color) !important;
        }
      }
    }

    .successText {
      @include themify($themes) {
        color: themed(success-modal-text-color)
      }
    }
  }

  .confirmButton {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 36px;
    color: $white;
    border-radius: 4px;
    font-size: 16px;

    @include themify($themes) {
      background-color: themed(theme-primary-color);
      border: 1px solid themed(theme-primary-color);
    }
  }

  .modalFooter {
    font-size: 16px;
    height: max-content;
    margin: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include themify($themes) {
      border-top: 1px solid #dcdcdc
    }

  }
}