@import '../../../scss/_web_base';

.memberAmountHistoryWrapper {
  font-size: 12px;
  color: #5d6d7b;
  position: relative;


  .operationBtn {
    border-radius: 5px;
    padding: 7px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
    color: #FFF;

    @include themify($themes) {
      background: themed(theme-primary-color);
      border-color: themed(theme-primary-color);
    }

    &:hover {
      @include themify($themes) {
        background: themed(theme-secondary-color);
        border-color: themed(theme-secondary-color);
      }
    }

    &.editAndSave {
      @include themify($themes) {
        background: themed(theme-primary-color);
        border-color: themed(theme-primary-color);
      }

      &:hover {
        @include themify($themes) {
          background: themed(theme-secondary-color);
          border-color: themed(theme-secondary-color);
        }
      }
    }

    &.add,
    &.cancel {
      background-color: #F56C6C;
      border-color: #F56C6C;

      &:hover {
        background: #f78989 !important;
        border-color: #f78989 !important;
      }
    }
  }

  .chargeClass {
    cursor: pointer;
    text-decoration: underline;
  }

  .passIcon {
    color: green;
    font-size: 20px;
  }

  .nopassIcon {
    color: red;
    font-size: 20px;
  }

  .actionArea {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .notify {
      max-width: max-content;
    }
  }

  .checkboxText {
    color: white;
    font-size: 12px;

    @include themify($themes) {
      background-color: themed(theme-primary-color);
    }
  }

  .searchInput {
    width: 80px;
    margin-left: 4px;
  }

  .unusualBet {
    color: #0000ff
  }

  .itemOdds {
    color: red;
    margin-left: 4px;
  }

  .midType {
    color: #2836f4
  }

  .installments {
    color: #299A26;
  }

  .green {
    color: green;
  }

  .blue {
    color: blue;
  }

  .red {
    color: red;
  }

  .m-w-80 {
    min-width: 100px;
  }

  .m-w-100 {
    min-width: 100px;
  }

  .m-w-130 {
    min-width: 130px;
  }

  .minxi {
    color: #0000ff;
    cursor: pointer;

    &:hover {
      color: red;
    }
  }

  .marginLeft1 {
    margin-left: 4px;
  }

  .marginLeft2 {
    margin-left: 8px;
  }

  .betWarningInput {
    width: 50%;
    max-width: 120px;
  }

  // copy from UserManagement component AgentList className： DirectAgentWrapper  
  .memberAmountHistoryFilterWrapper {
    height: max-content;
    display: flex;
    background-color: $white;
    border-radius: 5px;
    flex-direction: column;
    min-width: 975px;
    width: 100%;

    @include themify($themes) {
      font-family: themed(font-family);
    }

    .menuSelectedText {
      width: 90px !important;
    }

    .smallicon {
      font-size: 10px;
    }

    .subselection {
      position: absolute;
      top: 5;
      z-index: 1;
    }

    .hide {
      display: none;
    }

    .marginLeft5 {
      margin-left: 1.2em;
    }

    .filterWrapper {
      color: white;
      font-size: 12px;
      border-radius: 3px;
      border: 1px solid #dcdcdc;

      @include themify($themes) {
        background-color: themed(theme-primary-color);
      }

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type=number] {
        -moz-appearance: textfield;
      }

      .form-control {
        width: 17%;
      }

      .betQueryDatepicker {
        width: 185px !important;
      }

      .fixButton {
        color: $white;
        font-weight: 700;

        @include themify($themes) {
          background: themed(search-button-color);
          border: 1px solid themed(search-button-color);
        }

        border-radius: 5px;
        outline: none;
        font-size: 12px;
        position: relative;
        padding: 5px 15px 5px 15px;
        box-shadow: none;

        &:hover {
          @include themify($themes) {
            background: themed(search-button-color);
            border: 1px solid themed(search-button-color);
          }
        }
      }

      .searchButton {
        color: $white;
        font-weight: 700;

        @include themify($themes) {
          background: themed(search-button-color);
          border: 1px solid themed(search-button-color);
        }

        border-radius: 5px;
        outline: none;
        font-size: 12px;
        position: relative;
        padding: 5px 15px 5px 15px;
        box-shadow: none;

        &:hover {
          @include themify($themes) {
            background: themed(search-button-color);
            border: 1px solid themed(search-button-color);
          }
        }
      }
    }

    .popButton {
      .btn {
        color: $white;
        border-radius: 2px;
        outline: none;
        font-size: 11.5px !important;
        position: relative;
        padding: 1px 9px 1px 9px !important;
        box-shadow: none;
      }

      .idle {
        @include themify($themes) {
          border: 1px solid themed(theme-primary-color);
          background-color: themed(theme-primary-color);
        }
      }

      .active {
        @include themify($themes) {
          border: 1px solid themed(table-border-color);
          background-color: themed(table-border-color);
        }
      }
    }
  }
}