@import '../../../scss/web_base';

.returnAndOddsWrapper{
  font-size: 12px !important;

  .ant-input-number{
    width: 75px !important;
  }
  .OddsAndReturnWrapper{
    width: 100%;
    border:1px solid #dcdcdc ;
    &>div:not(:last-child){
      border-bottom: 1px solid #dcdcdc;
    }
    .ant-input-number-input{
      height: 30px !important;
      width: 70px !important;
    }
    .previewColumn{
      cursor: pointer;
      width: 90px !important;
      max-width: 90px !important;
      min-width: 90px !important;
      display: flex;
      flex-direction: column !important;
      .subNameWrapper{
        height: 40px !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .subNameWrapper:not(:last-child){
        border-bottom: 1px solid #dcdcdc;
      }
    }

    .moneyLimitColumn{
      cursor: pointer;
      width: 90px !important;
      max-width: 90px !important;
      min-width: 90px !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .OddsAndReturnHeader{
      @include themify($themes){
        background-color: themed(theme-third-color);
      }
      width: 100%;
      display: flex;
      .oddsDiffItemWrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &>div:first-child{
          border-bottom: 1px solid #dcdcdc;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &>div:last-child{
          display: flex;
          &>div{
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &>div:first-child{
            min-width: 130px !important;
            max-width: 130px !important;
            border-right: 1px solid #dcdcdc;
          }
        }
        &>div{
          min-height: 30px;
          width: 100% !important;
          flex: 1;
        }
      }

      &>div{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      &>div:not(:last-child){
        border-right: 1px solid #dcdcdc;
      }
    }

    .itemRowWrapper{
      &:hover{
        @include themify($themes){
          background-color: themed(theme-third-color);
        }
      }
      width: 100%;
      display: flex;
      &>div{
        flex: 1;
        display: flex;
      }
      &>div:not(:last-child){
        border-right: 1px solid #dcdcdc;
      }

      .categoryWrapper{
        &>div:first-child{
          width: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-right: 1px solid #dcdcdc;
        }
        &>div:last-child{
          flex: 1;
          .subNameWrapper{
            height: 40px !important;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .subNameWrapper:not(:last-child){
            border-bottom: 1px solid #dcdcdc;
          }
        }
      }

      .SingleOddsDiffWrapper{
        &>div:first-child{
          width: 130px !important;
          border-right: 1px solid #dcdcdc;
          .subNameWrapper{
            padding-left: 5px;
            height: 40px !important;
            display: flex;
            align-items: center;
          }
          .subNameWrapper:not(:last-child){
            border-bottom: 1px solid #dcdcdc;
          }
        }

        .singleReturnWrapper{
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

    }
  }









  .ant-input{
    text-align: center !important;
  }
  .inputStyle{
    width: 100px;
  }
  .is-animated {
    margin: 10px 0px !important;
  }

  .multiChange{
    @include themify($themes){
      background-color: themed(theme-third-color) !important;
      }
  }
  .singleChange{
      background-color: #ffffd7 !important;
  }

  .operationButton {
    color: $white;
    border-radius: 5px;
    position: relative;
    padding: 4px 6px;
    box-shadow: none;
    cursor: pointer;
    &.find{
      background: #f56f62;
      border: 1px solid #f56f62;
    }
    &.generate{
      background-color: #E6A23C;
      border-color: #E6A23C;
    }
    &.handle{
      background-color: #409EFF;
      border-color: #409EFF;
    }
    &.copy {
      background-color: #67C23A;
      border-color: #67C23A;
    }
  }

  .squareCheckStyle{
    .ant-radio-checked{
      .ant-radio-inner{
      @include themify($themes){
        border-color: themed(theme-primary-color) !important;
        background-color: themed(theme-primary-color) !important;
        }
      }
    }
    .ant-radio-inner{
      border-radius: 0% !important;
      &::after{
        background-color: white !important;
      }
    }
    .ant-radio-wrapper{
      font-size: 12px !important;
    }
  }

  .operationSection{
    display: flex;
    .categorySelect{
      border: 1px solid rgb(220,220,220);
      border-radius: 3px;
      padding: 8px;
      margin-right: 10px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      @include themify($themes){
        border: 1px solid themed(table-border-color);
        background-color: themed(lottery-menu-color);
      }
    }
    .dropdownArea{
      display: flex;
      word-break: keep-all;
      align-items: center;
      flex: 1;
      color: #5d6d7b;
      border-radius: 3px;
      border: 1px solid #dcdcdc;
      @include themify($themes){
        border: 1px solid themed(table-border-color);
        background-color: themed(theme-third-color);
      }
      .longtextCheckBox{
        align-items: center;
      }
      .dropDownList{
        width: 100px;
        &.shortvalue{
          width: 75px;
        }
        &.longTextDropDown{
          width: 130px;
        }
      }
    }
  }

  .selectSection{
    border: 1px solid rgb(220,220,220);
    border-radius: 3px;
    &>div:first-child{
      border-bottom: 1px solid rgb(220,220,220);
      .templateStyle{
        padding: 12px;
        cursor: pointer;
        border-bottom: 2px;
        &:hover{
          @include themify($themes){
            background-color: themed(theme-third-color);
            border-bottom: 2px solid themed(theme-third-color);
          }
        }
        &.selected{
          @include themify($themes){
            border-bottom: 2px solid themed(theme-primary-color);
          }
        }
      }
    }
    &>div:last-child{
      padding: 12px;
    }
  }
  .contentSection{
    th, td{
      padding: 0px !important;
    }
    .panHeaderStyle{
      &>div:first-child{
        border-bottom: 1px solid rgb(220,220,220);
        padding: 5px 0px;
      }
      &>div:last-child{
        display: flex;
        &>div:first-child{
          border-right: 1px solid rgb(220,220,220);
          flex: 2;
        }
        &>div:last-child{
          flex: 1;
        }
        &>div{
          padding: 5px 0px;
        }
      }
    }
    .firstCellStyle{
      width: 250px !important;
    }
    .categoryWrapper{
      display: flex;
      &>div{
        flex: 1;
      }
      &>div:first-child{
        border-right: 1px solid rgb(220,220,220);
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &>div:last-child{
        &>div{
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &>div:not(:last-child){
        border-bottom: 1px solid rgb(220,220,220);
        }
      }
    }
    .pankouContentWarpper{
      display: flex;
      height: 100%;
      &>div:first-child{
        flex: 2;
        border-right: 1px solid rgb(220,220,220);
        &>div:not(:last-child){
          border-bottom: 1px solid rgb(220,220,220);
        }
        &>div{
          height: 45px;
          display: flex;
          align-items: center;
          padding-left: 20px;
        }
      }
      &>div:last-child{
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .ant-input{
      width: 70px;
    }
  }
}