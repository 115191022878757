@import '../../../scss/web_base';

.latestAnnouncementModalWrapperWeb {
  border-radius: 5px;
  width: 850px;
  @include themify($themes) {
    font-family: themed(font-family);
  }
  .modalHeader {
    line-height: 34px;
    color: $white;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
      font-family: themed(font-family);
    }
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    .closeIcon {
      cursor: pointer;
    }
  }
  .modalContent {
    height: auto;
    max-height: 550px !important;
    overflow-y: scroll;
    width: 100% !important;
    font-size: 12px;
    flex-direction: column;
    @include themify($themes) {
      font-family: themed(font-family);
    }

    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background: #ADACAC;
      border-radius: 5px;
    }
    .contentNotice{
      width: 75%;
      word-break: break-all;
    }
    .dateNotice{
      width: 25%;
    }
  }
  .modalFooter {
    font-size: 16px;
    height: max-content;
    margin: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    @include themify($themes) {
      background-color:themed(theme-third-color);
    }
    .confirmButton {
      width: 120px;
      height: 36px;
      color: $white;
      border-radius: 4px;
      font-size: 16px;
      text-align: center;
      @include themify($themes){
        background-color: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
    }
  }
}

