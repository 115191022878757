@import '../../../scss/web_base';

.betDetailModalWrapperWeb {
  border-radius: 5px;
  width: 850px;
  @include themify($themes) {
    font-family: themed(font-family);
  }
  .modalHeader {
    line-height: 34px;
    color: $white;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
      font-family: themed(font-family);
    }
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    .closeIcon {
      cursor: pointer;
    }
  }
  .modalContent {
    width: 100% !important;
    background-color: white;
    font-size: 12px;
    height: max-content;
    margin: 0;
    flex-direction: column;
    padding-bottom: 2em;
    color: #5d6d7b;
    @include themify($themes) {
      font-family: themed(font-family);
    }
    .confirmText{
      text-align: center;
    }
    .exclamationIcon {
      height: 7em;
      width: 7em;
      circle{
        @include themify($themes){
          fill: themed(theme-secondary-color) !important;
        }
      }
    }
    .successText {
      @include themify($themes) {
        color: themed(success-modal-text-color)
      }
    }
  }
  .modalFooter {
    font-size: 16px;
    height: max-content;
    margin: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    @include themify($themes) {
      background-color:themed(theme-third-color);
    }
    .confirmButton {
      width: 120px;
      height: 36px;
      color: $white;
      border-radius: 4px;
      font-size: 16px;
      text-align: center;
      @include themify($themes){
        background-color: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
    }
  }
}

