@import '../../../scss//web_base';

.LotterySettingWrapper {
  position: relative;
  width: 100%;

  .button {
    cursor: pointer;
    color: $white;
    font-weight: 700;
    border-radius: 5px;
    outline: none;
    font-size: 12px;
    position: relative;
    padding: 3px 15px 3px 15px;
    box-shadow: none;
    &.find{
      background: #f56f62;
      border: 1px solid #f56f62;
    }
    &.handle{
      background-color: #409EFF;
      border-color: #409EFF;
    }
    &.green{
      background-color: #67C23A;
      border-color: #67C23A;
    }
  }

  table{
    width: 100%;
    .dropdownAndInputStyle{
      width: 150px;
    }
    td,th{
      color: #5d6d7b;
      font-size: 12px;
      font-weight: normal;
      padding: 6px 8px;
      margin: 0 auto;
      @include themify($themes) {
        border: 1px solid themed(table-border-color);
      }
      vertical-align: middle;
    }
    .titleHeader{
      @include themify($themes){
        background-color: themed(theme-third-color);
        color: themed(table-header-text-color);
      }
      font-weight: normal;
      text-align: center;
    }
    .tableHeader{
      @include themify($themes){
        background-color: themed(theme-third-color);
        color: themed(table-header-text-color);
      }
      font-weight: normal;
      text-align: right;
    }
    tbody {
      tr {
        .topLeftBorderNone {
          border-top: none !important;
          border-left: none !important;
        }
      }
    }
  }
}