@import '../../../scss/web_base';

.QsWrapper {
  border-collapse:collapse;
  @include themify($themes){
    color: themed(table-header-text-color);
  }
  .rowBorder {
    text-align: center;
    align-items: center;
    @include themify($themes){
      border-right: 1px solid themed(table-border-color);
    }
  }
  .myborder {
    min-height: 35px;
    @include themify($themes){
      border-left: 1px solid themed(table-border-color);
      border-bottom: 1px solid themed(table-border-color)
    }
  }
  .p2 {
    position: inherit;
    padding: 6px;
    margin: 6px;
  }
  .qsRed {
    color: #f70824;
  }
  .qsGreen {
    color: rgb(128, 194, 105);
  }
  .qsBlue {
    color: #448eed;
  }
  .adjustment {
    border-radius: 3px;
    padding: 2px 8px;
    @include themify($themes){
      border: 1px solid themed(theme-primary-color);
      color: themed(theme-primary-color);
    }
    &.active {
      color: white !important;
      @include themify($themes){
        background-color: themed(theme-primary-color);
      }
    }
  }
  .bigIcon {
    font-size: 25px;
    align-items: center;
    text-align: center;
    line-height: 0;
  }
  .footer {
    width: 100%;
    max-height: 60px;
    position: fixed;
    bottom:24px;
    left:0;
    overflow: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    @include themify($themes){
      background-color: themed(theme-third-color);
      color: themed(table-header-text-color);
    }
    input[type='number'], input[type='text'] {
      height: 24px !important;
      width: 80px !important;
      font-size: 12px;
      text-align: center;
      border-radius: 3px;
      @include themify($themes){
        border: 1px solid themed(table-border-color);
        color: themed(table-header-text-color);
      }
    }
  }
  .oddHeader{
    border-collapse: collapse;
    font-size: 12px;
    padding: 7px;
    text-align: center;
    font-weight: 600;
    @include themify($themes){
      border-top: 1px solid themed(table-border-color);
      border-left: 1px solid themed(table-border-color);
      border-right: 1px solid themed(table-border-color);
      background-color: themed(theme-third-color);
      color: themed(table-header-text-color);
    }
    &.drawbottom {
      @include themify($themes){
        border-bottom: 1px solid themed(table-border-color);
      }
    }
  }
  .button {
    padding: 3px 15px 3px 15px !important;
    &.cancel {
      @include themify($themes){
        background-color: themed(small-button-color) !important;
      }
    }
    &.reset {
      @include themify($themes){
        border: 1px solid themed(reset-button-color) !important;
        background-color: themed(reset-button-color) !important;
      }
    }
    &.deselect {
      color: white !important;
      background-color: rgb(249, 108, 65) !important;
    }
  }
  .qsType2 {
    border-radius: 3px;
    float:right;
    background-color: #fff5e9;
    // background-color: rgba(255, 255, 255, 0.5);
    @include themify($themes){
      border: 1px solid themed(table-border-color) !important;
    }
  }
  .radioButtonGroup {
    .form-check-input {
      background:white;
      border-radius: 3px;
      padding:2px;
      height: 20px;
      width: 20px;
      @include themify($themes){
        border: 1px solid themed(table-border-color);
      }
      &::after{
        @include themify($themes){
          background:white;
          border: none;
        }
      }
      &:checked {
        background-color: #f96c41;
        @include themify($themes){
          color: themed(theme-lighter-color);
        }
        .el-radio__label { 
          @include themify($themes){
            color: themed(theme-primary-color);
            }
        }
      }
  }
  }
}