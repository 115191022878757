@import '../../../scss/web_base';

.DecreaseOddsSettingWarpper{
    height: max-content;
    display: flex;
    background-color: $white;
    border-radius: 5px;
    flex-direction: column;
    min-width: 975px;
    width: 100%;
    font-size: 12px;
    @include themify($themes){
      font-family: themed(font-family);
    }
    .subselection {
      position:absolute;
      top:5;
      z-index:1;
    }
    .hide {
      display: none;
    }
    .menuWrapper {
      background: white;
      height: 30px;
      width: 120px !important;
      font-size: 12px;
      text-align: left;
      border-radius: 3px;
      padding: 3px 12px;
      @include themify($themes){
        border: 1px solid themed(table-border-color);
        color: themed(theme-primary-color);
      }
    }

    .menuSelectedText {
        width: 90px !important;
    }
    .menuactive {
      color:white !important;
      font-weight: 600;
      @include themify($themes){
        border: 1px solid themed(theme-primary-color);
        background: themed(theme-primary-color);
      }
    }

    .subMenu  {
      width: 100%;
      border-radius: 3px;
      border: 1px solid #dcdcdc;
      @include themify($themes){
        background-color: themed(theme-third-color);
      }
    }
    .footer {
      width: 100%;
      position: fixed;
      bottom:24px;
      left:0;
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      @include themify($themes){
        background-color: themed(theme-third-color);
        color: themed(table-header-text-color);
      }
    }
    .kuaijieInputStyle{
      width: 100px;
    }
    
    .operationBtn {
      border-radius: 5px;
      padding: 7px 15px;
      white-space: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: #FFF;

      &.editAndSave {
        @include themify($themes) {
          background: themed(theme-primary-color);
          border-color: themed(theme-primary-color);
        }

        &:hover {
          @include themify($themes) {
            background: themed(theme-secondary-color);
            border-color: themed(theme-secondary-color);
          }
        }
      }

      &.add {
        background-color: #67C23A;
        border-color: #67C23A;

        &:hover {
          background: #85ce61 !important;
          border-color: #85ce61 !important;
        }
      }

      &.remove {
        background-color: #F56C6C;
        border-color: #F56C6C;

        &:hover {
          background: #f78989 !important;
          border-color: #f78989 !important;
        }
      }
    }
}