@import '../../../scss/_web_base';

.AccessConfigureWrapper {
  position: relative;
  .accessSettingContent{
    display: flex;
    &>div{
      flex: 1;
    }
  }

  .AccessConfigureMenuManagementHeader{
    display: flex;
    justify-content: center;
    align-items: center;
    word-break: keep-all;
    height: 53px;
    @include themify($themes){
      background-color: themed(theme-third-color);
      border-top: 1px solid themed(table-border-color);  
      border-left: 1px solid themed(table-border-color);  
      border-right: 1px solid themed(table-border-color);  
    }

    .menuNameInput{
      width: 150px;
    }
  }

  .operationBtn{
    border-radius: 5px;
    padding: 7px 10px;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    color: #FFF;
    @include themify($themes){
      background: themed(theme-primary-color);
      border-color:themed(theme-primary-color);
    }
    &:hover{
        @include themify($themes){
            background: themed(theme-secondary-color);
            border-color:themed(theme-secondary-color);
          }
    }

    &.stopAndStart{
      background-color: #E6A23C;
      border-color: #E6A23C;
      &:hover{
          background: #ebb563 !important;
          border-color: #ebb563 !important;
      }
  }

    &.editAndSave{
      @include themify($themes){
          background: themed(theme-primary-color);
          border-color:themed(theme-primary-color);
        }
      &:hover{
          @include themify($themes){
              background: themed(theme-secondary-color);
              border-color:themed(theme-secondary-color);
            }
      }
  }

    &.add, &.cancel{
      background-color: #F56C6C;
      border-color: #F56C6C;
      &:hover{
          background: #f78989 !important;
          border-color: #f78989 !important;
      }
    }
  }

}