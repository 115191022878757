@import '../../../scss/_web_base';


.SetReminderAmountWrapper {
  font-size: 12px;
  color: #5d6d7b;
  position: relative;

  .hidden {
    visibility: hidden;
  }

  .br-0 {
    border-right-width: 0px;
  }

  .button {
    color: $white;
    font-weight: 700;
    border-radius: 5px;
    outline: none;
    font-size: 12px;
    position: relative;
    padding: 5px 15px 5px 15px;
    box-shadow: none;

    &.edit {
      background: #f56f62;
      border: 1px solid #f56f62;
    }

    &.reset {
      background-color: #409EFF;
      border-color: #409EFF;
    }
  }

  .red {
    color: red;
  }

  .pl-1 {
    padding-left: 4px;
  }

  .lotteryList {
    @include themify($themes) {
      border: 1px solid themed(table-border-color);
    }

    font-weight: bold;
    // max-width: 1380px;
    min-width: 975px; // same min width as filter wrapper in this file 
    background-color: #ffeee3;
    display: flex;
    align-items: center;
    overflow-x: scroll;
    padding-top: 10px;
    padding-bottom: 10px;

    .lotteryItem {
      text-align: center;
      cursor: pointer;
      padding: 10px;
      min-width: max-content;

      &.active {
        @include themify($themes) {
          border: 1px solid themed(table-border-color);
        }

        background-color: white;
      }
    }

  }

  .oddHeader {
    border-collapse: collapse;
    font-size: 12px;
    padding: 7px;
    text-align: center;
    font-weight: 600;

    @include themify($themes) {
      border-top: 1px solid themed(table-border-color);
      border-left: 1px solid themed(table-border-color);
      border-right: 1px solid themed(table-border-color);
      background-color: themed(theme-third-color);
      color: themed(table-header-text-color);
    }

    &.drawbottom {
      @include themify($themes) {
        border-bottom: 1px solid themed(table-border-color);
      }
    }
  }

  .refreshIcon {
    width: 30px;
  }

  .filterWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .info_tishi {
    color: #808080
  }

  .minxi {
    color: '#0000ff';
    cursor: pointer;

    &:hover {
      color: red;
    }
  }

  .marginLeft1 {
    margin-left: 4px;
  }

  .betWarningInput {
    width: 50%;
    max-width: 120px;
  }

}