@import "../../../scss/web_base";

.authFooter {
  padding: 0 15px;
  background-color: $white;
  box-shadow: 0 0 10px rgba($black, 0.1);
  z-index: 10000;
  height: 24px;
  position: relative;
  min-width: 1440px;

  .paoMaDeng{
    .child{
      padding-right: 100vw;
    }
  }

  .footerMore {
    background-color: white;
    @include themify($themes){
      color: themed(footer-more-color);
    }
    z-index: 1;
    position: absolute;
    right: 10px;
    padding: 0px 1rem;
    top: 0px;
    .moreButton {
      cursor: pointer;
    }
  }
}
