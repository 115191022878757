@import '../../../scss/web_base';

.transactionQueryWrapper{
    height: max-content;
    display: flex;
    background-color: $white;
    border-radius: 5px;
    flex-direction: column;
    min-width: 975px;
    width: 100%;
    .detailLink{
      cursor: pointer;
    }
    @include themify($themes){
      font-family: themed(font-family);
    }
    .menuSelectedText {
      width: 90px !important;
    }
    .subselection {
      position:absolute;
      top:5;
      z-index:1;
    }
    .hide {
      display: none;
    }
    .menuWrapper {
      background: white;
      height: 30px;
      width: 120px !important;
      font-size: 12px;
      text-align: left;
      border-radius: 3px;
      padding: 5px 10px 5px 10px;
      color:black;
    }

    .successValue{
      color: #80c269;
    }
    .negativeValue{
      color: #f70824;
    }

    .filterWrapper {
      color: #5d6d7b;
      font-size: 12px;
      border-radius: 3px;
      border: 1px solid #dcdcdc;
        display: flex;
        width: 100%;
        @include themify($themes){
          border: 1px solid themed(table-border-color);
        }
        .leftPart{
          width: 95%;
          @include themify($themes){
            border-right: 1px solid themed(table-border-color);
          }
          &>div{
            display: flex;
            width: 100%;
            align-items: center;
            padding: 5px 10px;
            &>div{
              display: flex;
              align-items: center;
              white-space: nowrap;
              &>span:first-child{
                margin-right: 5px;
              }
            }
          }

          &>div:nth-of-type(1){
            @include themify($themes){
              border-bottom: 1px solid themed(table-border-color);
            }
          }
        }
        .rightPart{
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          &>div{
            cursor: pointer;
          }
        }

        .inputStyle{
          width: 120px;
        }
        .searchButton {
          color: $white;
          font-weight: 700;
          @include themify($themes) {
            background: themed(search-button-color);
            border: 1px solid themed(search-button-color);
          }
          border-radius: 5px;
          outline: none;
          font-size: 12px;
          position: relative;
          padding: 5px 15px 5px 15px;
          box-shadow: none;
          &:hover {
            @include themify($themes) {
              background: themed(search-button-color);
              border: 1px solid themed(search-button-color);
            }
          }
        }
      }
}