@import '../../../scss//web_base';

.EmailSMSSettingWrapper {
  position: relative;

  .noWrapStyle{
    white-space: nowrap;
    padding: 0px 20px;
  }
  .oldValueStyle,.newValueStyle{
    max-width: 300px;
    text-align: left;
  }
  .form-check-input[type=checkbox] {
    border-radius: 0.15em;    }
  .el-input__inner {
    font-size: 12px;
    height: 30px;
  }
  .filterWrapper {
    border-radius: 3px;
    color: #5d6d7b;
    font-size: 18px;
    width: 100%;
    border-right: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    input[type=text], input[type=number] {
      height: 30px;
      width: 120px;
      font-size: 12px;
      padding: 5px 8px 5px 8px;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .button {
      color: $white;
      font-weight: 700;
      border-radius: 5px;
      outline: none;
      font-size: 12px;
      position: relative;
      padding: 5px 15px 5px 15px;
      box-shadow: none;
      &.find{
        background: rgb(249, 108, 65);
        border: 1px solid #f56f62;
      }
    }
    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
      width: 60px !important;
    }
    .textAreaInput {
      .form-control {
        font-size: 12px;
        width: 180px;
      }
    }
    .smallInput {
      input[type=text], input[type=number] {
        height: 30px;
        width: 60px;
        font-size: 12px;
        padding: 5px 8px 5px 8px;
      }
    }
  }
  .button {
    color: $white;
    font-weight: 700;
    border-radius: 5px;
    outline: none;
    font-size: 12px;
    position: relative;
    padding: 4px 15px 4px 15px;
    box-shadow: none;
    &.find{
      background: #f56f62;
      border: 1px solid #f56f62;
    }
    &.right {
      float:right;
    }
    &.handle{
      background-color: #409EFF;
      border-color: #409EFF;
    }
    &.yellow{
      background-color: #ebb563;
      border-color: #ebb563;
    }
  }
  .bigWrapper {
    box-shadow: 0 0px 10px rgba(0,0,0,0.15);
    transition: box-shadow 0.3s ease-in-out;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: 100%;

  }
  .titleWrapper {
    border-bottom: 1px solid #dcdcdc;
    color: #5d6d7b;
    font-size: 14px;
    font-weight: bold;
    padding: 15px 15px 15px 20px;
    background-color: #eaf3ff;
  }
}