@import '../../../scss/web_base';

.HandleAgentRebateModalWrapperWeb {
  width: 550px;
  @include themify($themes) {
    font-family: themed(font-family);
  }
  .modalHeader {
    line-height: 45px;
    color: $white;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
      font-family: themed(font-family);
    }
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
    .closeIcon {
      cursor: pointer;
    }
  }
  .modalContent {
    font-size: 16px;
    height: max-content;
    margin: 0;
    padding-bottom: 2em;
    .rebateTable{
      border: 1px solid #DCDCDC;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &>div:first-child{
        border-bottom: 1px solid #DCDCDC;
      }
      &>div{
        width: 100%;
        &:hover{
          @include themify($themes){
            background-color: themed(theme-third-color);
          }
        }
        &>div:first-child{
          border-right: 1px solid #DCDCDC;
          @include themify($themes){
            background-color: themed(theme-third-color);
          }
        }
        &>div{
          height: 54px;
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .confirmText{
      text-align: center;
    }
    .exclamationIcon {
      height: 7em;
      width: 7em;
      circle{
        @include themify($themes){
          fill: themed(theme-secondary-color) !important;
        }
      }
    }
    .successText {
      @include themify($themes) {
        color: themed(success-modal-text-color)
      }
    }
  }
  .modalFooter {
    font-size: 16px;
    height: max-content;
    margin: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include themify($themes) {
      border-top: 1px solid #dcdcdc
    }
    .confirmButton {
      width: 120px;
      height: 36px;
      color: $white;
      border-radius: 4px;
      font-size: 16px;
      text-align: center;
      @include themify($themes){
        background-color: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
    }
    .cancelButton {
      width: 120px;
      height: 36px;
      border-radius: 4px;
      font-size: 16px;
      text-align: center;
      background-color: $white;
      @include themify($themes){
        background-color: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
    }
  }
}
