@import '../../../scss/web_base';

.winLostNotiModalWrapper {
  border-radius: 5px;
  width: 450px;
  @include themify($themes) {
    font-family: themed(font-family);
  }
  .modalHeader {
    line-height: 34px;
    color: $white;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
      font-family: themed(font-family);
    }
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    .closeIcon {
      cursor: pointer;
    }
  }
  .modalContent {
    width: 100% !important;
    background-color: white;
    font-size: 12px;
    margin: 0;
    max-height: 500px;
    padding-bottom: 2em;
    color: #5d6d7b;
    border:none;
    border-collapse: collapse;
    @include themify($themes) {
      font-family: themed(font-family);
    }
    .tableHeader{
      @include themify($themes){
        background-color: themed(theme-third-color);
        color: themed(table-header-text-color);
      }
      font-size: 12px;
      font-weight: normal;
    }
    td,th{
      text-align: center;
      color: #5d6d7b;
      font-size: 12px;
      font-weight: normal;
      padding: 6px 4px;
      margin: 0 auto;
      @include themify($themes) {
        border: 1px solid themed(table-border-color);
      }
      vertical-align: middle;
    }
    tbody {
      tr {
        .topLeftBorderNone {
          border-top: none !important;
          border-left: none !important;
        }
      }
    }
    .oneCharCol {
      width: 50px;
    }
    th{
      font-weight: normal !important;
    }
    .confirmText{
      text-align: center;
    }
    .exclamationIcon {
      height: 7em;
      width: 7em;
      circle{
        @include themify($themes){
          fill: themed(theme-secondary-color) !important;
        }
      }
    }
    .successText {
      @include themify($themes) {
        color: themed(success-modal-text-color)
      }
    }
  }
  input[type=number] {
    font-size: 12px;
    width: 120px;
    height: 30px;
  }
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button {  
    opacity: 1;
  }
  .el-input__inner {
    font-size: 12px;
    height: 30px;
  }
  .modalFooter {
    font-size: 16px;
    margin: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    @include themify($themes) {
      background-color:themed(theme-third-color);
    }
    .confirmButton {
      width: 120px;
      height: 36px;
      color: $white;
      border-radius: 4px;
      font-size: 16px;
      text-align: center;
      @include themify($themes){
        background-color: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
    }
  }
}

