@import '../../scss/web_base';

.inTimeSheetTable {
  min-width: 340px;
  width: 24.5%;
  padding: 0;
  border: 1px solid $border; 
  height: max-content;


  &.hk6YiXiaoWS{
    width: 49% !important;
  }
  &.hk6LiangMian{
    width: 49% !important;
  }
  &.hk6ZhengMa16{
    width: 32% !important;
    margin-bottom: 30px;
  }
  &.hk6TouWeiWX{
    width: 32% !important;
  }
  &.hk6TeZhengZongXiao{
    width: 32% !important;
  }

  .tableTitle{
    @include themify($themes){
      background-color: themed(theme-third-color);
    }
  }
  .headerTitle{
    &>div{
      display: flex;
      justify-content: center;
      align-items: center;
      @include themify($themes){
        background-color: themed(theme-third-color);
      }
    }
    &>div:not(:last-child){
      @include themify($themes){
          border-right: 1px solid themed(table-border-color);
          }
      }

  }
  @include themify($themes) {
    color: themed(data-table-text-color);
  }
  .column-number {
    width: 20%;
    height: 38px;
    &.k3_category{
      flex-direction: column !important;
      height: auto !important;
      @include themify($themes){
        border-top: 1px solid themed(table-border-color);
        border-right: 1px solid themed(table-border-color);
        }
    }
  }
  .column-odds {
    width: 20%;
    height: 38px;
  }
  .column-amount {
    width: 20%;
    height: 38px;
  }
  .column-profit {
    width: 20%;
    height: 38px;
  }
  .column-buhuo {
    width: 20%;
    height: 38px;
  }

}