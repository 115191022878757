@import '../../../scss/web_base';

.loadingModalWrapper {
  width: 550px;
  @include themify($themes) {
    font-family: themed(font-family);
  }
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}
