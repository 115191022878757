@import '../../../scss/_web_base';

.CompanySettingWrapper {
  position: relative;
  .thirdPartyGamesFooter{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mousePointIn{
    color: white;
    .activeBtn{
      @include themify($themes){
        background: themed(theme-primary-color);
        border-color:themed(theme-primary-color);
      }
    }
    .nonActiveBtn{
        background: #F56C6C;
        border-color:#F56C6C;
    }

    cursor: pointer;
    .shiftBtn{
      display: none;
    }
    .statusBtn, .shiftBtn{
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      width: 55px;
      padding: 3px 0px;
    }
    &:hover{
      .statusBtn{
        display: none;
      }
      .shiftBtn{
        display: flex;
      }
    }
  }

  .detailLink{
    cursor: pointer;
  }
  .companyLogo{
    width: 100px;
  }

  .backIcon{
    cursor: pointer;
  }

  .el-textarea{
    width: 300px;
  }
  .messageTitleBox{
    width: 300px !important;
  }

  .thirdPartyGamesTable{
    .thirdPartyGamesTitle{
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include themify($themes){
        background: themed(theme-third-color);
      }
    }
    @include themify($themes) {
    border: 1px solid themed(table-border-color);
    }
    &>div:not(:last-child){
      @include themify($themes) {
      border-bottom: 1px solid themed(table-border-color);
      }
    }
    .thirdPartyGamesRow{
      display: flex;
      width: 100%;
      &>div{
        padding: 5px 10px;
      }
      &>div:first-child{
        @include themify($themes){
          background: themed(theme-third-color);
        }
        width: 25%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @include themify($themes) {
        border-right: 1px solid themed(table-border-color);  
        }  
      }
    }
  }

  .addNewGameBox{
    display: flex;
    align-items: center;
    padding: 10px;
    @include themify($themes){
      background-color: themed(theme-third-color);
    }
  }
  .uploadBtn{
    width: 0px !important;
    height: 0px !important;
  }

  .companyLogoPreview{
    max-width: 200px;
    max-height: 200px;
  }

  .operationBtn{
    border-radius: 5px;
    padding: 7px 10px;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    color: #FFF;
    @include themify($themes){
      background: themed(theme-primary-color);
      border-color:themed(theme-primary-color);
    }
    &:hover{
        @include themify($themes){
            background: themed(theme-secondary-color);
            border-color:themed(theme-secondary-color);
          }
    }

    &.stopAndStart{
      background-color: #E6A23C;
      border-color: #E6A23C;
      &:hover{
          background: #ebb563 !important;
          border-color: #ebb563 !important;
      }
  }

    &.editAndSave{
      @include themify($themes){
          background: themed(theme-primary-color);
          border-color:themed(theme-primary-color);
        }
      &:hover{
          @include themify($themes){
              background: themed(theme-secondary-color);
              border-color:themed(theme-secondary-color);
            }
      }
  }


    &.add, &.cancel{
      background-color: #F56C6C;
      border-color: #F56C6C;
      &:hover{
          background: #f78989 !important;
          border-color: #f78989 !important;
      }
    }
  }

  .level1CompanyEditWrapper {
    position: relative;
    .operationBtn{
      border-radius: 5px;
      padding: 7px 10px;
      line-height: 1;
      white-space: nowrap;
      cursor: pointer;
      color: #FFF;
      @include themify($themes){
        background: themed(theme-primary-color);
        border-color:themed(theme-primary-color);
      }
      &:hover{
          @include themify($themes){
              background: themed(theme-secondary-color);
              border-color:themed(theme-secondary-color);
            }
      }
  
      &.reset,&.cancel{
        background-color: #F56C6C;
        border-color: #F56C6C;
        &:hover{
            background: #f78989 !important;
            border-color: #f78989 !important;
        }
        .plus_icon{
          margin-right: 3px;
          border-radius: 50%;
          padding: 0px 2px;
          display: inline;
          background-color: white !important;
        .el-icon-plus{
          color: #F56C6C;
        }
      }
      }
    }
  
    .sectionWrapper{
      width: 100%;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      padding: 15px 10px;
      margin: 10px 0;
      border-radius: 5px;
      border: 1px solid #ddd;
      background: #ffffff;
  
      .el-input{
        width: 200px;
      }
      .el-input__inner{
        height: 28px;
      }
      .el-select-dropdown__item.selected,
      .el-select-dropdown__item.selected.hover {
        font-weight: 700;
        @include themify($themes){
          color: themed(theme-primary-color);
          background-color: themed(drop-down-hover);
        }
      }
  
      .el-select {
        .el-input__inner:hover, .el-input__inner:focus {
          @include themify($themes){
            border-color: themed(theme-primary-color);  
            background-color: themed(drop-down-hover);
          }
        }
        .el-input__inner {      
          width: 200px;
          padding-right: 10px;
          .is-active {
          @include themify($themes){
            color: themed(theme-primary-color);
            border-color:themed(theme-primary-color);
          }
        }
        }   
      }  
      .el-select-dropdown__item:hover {
        @include themify($themes){
          background-color: themed(drop-down-hover);
        }
      }
      .el-select-dropdown {
        border-radius: 4px !important;
      }
  
      .tableTitleStyle{
        height: 37px;
        width: 100%;
        border-top: 1px solid #ddd;
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
        @include themify($themes){
          background-color: themed(theme-third-color);
        }
        display: flex;
        align-items: center;
        justify-content: center;
      }
  
      .tableSection{
        position: relative;
        border: 1px solid #ddd;
        min-width: 50%;
        width: 100%;
        &>div:not(:last-child){
          border-bottom: 1px solid #ddd;
        }
        &.labelCellBackground{
          .rowStyle{
            &>div:first-child{
              @include themify($themes){
                background-color: themed(theme-third-color);
              }
            }
          }
        }
        .rowStyle{
          position: relative;
          display: flex;
          min-height: 37px;
          &.bg-grey{
            background-color: #f9f9f9;
          }
          &>div:first-child{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 30%;
            border-right: 1px solid #ddd;
            padding-right: 10px;
          }
          &>div:last-child{
            padding-left: 5px;
            display: flex;
            align-items: center;
            flex: 1;
            flex-wrap: wrap;
          }
          .lotterySelection{
            width: 130px;
            margin: 0px;
          }
        }
  
      }
  
    }
  }
}