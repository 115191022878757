@import '../../scss/web_base';

.sideMenuWrapper {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    @include themify($themes){
        background-color: themed(theme-primary-color);
      }

    .menuItem{
        width: 52px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5px 0px;
        color: white;
        cursor: pointer;
        border-bottom: 1px solid rgba(0,0,0,.16);
        border-top: 1px solid hsla(0,0%,100%,.4);
        &:hover{
        border-bottom: 1px solid #ffbd68!important;
        }
        &.selected{
            background-color: #ffbd68;
        }
    }
}