@import '../../../../scss//web_base';

.MaxBuHuoWrapper{
    font-size: 12px !important;
    .inputStyle{
      width: 100px;
    }
    .is-animated {
      margin: 10px 0px !important;
    }
  
    .multiChange{
      @include themify($themes){
        background-color: themed(theme-third-color) !important;
        }
    }
    .singleChange{
        background-color: #ffffd7 !important;
    }
  
    .operationButton {
      color: $white;
      border-radius: 5px;
      position: relative;
      padding: 4px 6px;
      box-shadow: none;
      cursor: pointer;
      &.find{
        background: #f56f62;
        border: 1px solid #f56f62;
      }
      &.generate{
        background-color: #E6A23C;
        border-color: #E6A23C;
      }
      &.handle{
        background-color: #409EFF;
        border-color: #409EFF;
      }
      &.copy {
        background-color: #67C23A;
        border-color: #67C23A;
      }
    }
  
    .squareCheckStyle{
      .ant-radio-checked{
        .ant-radio-inner{
        @include themify($themes){
          border-color: themed(theme-primary-color) !important;
          background-color: themed(theme-primary-color) !important;
          }
        }
      }
      .ant-radio-inner{
        border-radius: 0% !important;
        &::after{
          background-color: white !important;
        }
      }
      .ant-radio-wrapper{
        font-size: 12px !important;
      }
    }
  
    .operationSection{
      display: flex;
      .categorySelect{
        min-width: 246px;
        height: 39px;
        border: 1px solid rgb(220,220,220);
        border-radius: 3px;
        padding: 8px;
        margin-right: 10px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        @include themify($themes){
          border: 1px solid themed(table-border-color);
          background-color: themed(lottery-menu-color);
        }
      }
      .dropdownArea{
        display: flex;
        word-break: keep-all;
        align-items: center;
        flex: 1;
        color: #5d6d7b;
        border-radius: 3px;
        border: 1px solid #dcdcdc;
        @include themify($themes){
          border: 1px solid themed(table-border-color);
          background-color: themed(theme-third-color);
        }
        .longtextCheckBox{
          align-items: center;
        }
        .dropDownList{
          width: 100px;
          &.shortvalue{
            width: 75px;
          }
          &.longTextDropDown{
            width: 130px;
          }
        }
      }
    }
  
    .selectSection{
      border: 1px solid rgb(220,220,220);
      border-radius: 3px;
      &>div:first-child{
        border-bottom: 1px solid rgb(220,220,220);
        .templateStyle{
          padding: 12px;
          cursor: pointer;
          border-bottom: 2px;
          &:hover{
            @include themify($themes){
              background-color: themed(theme-third-color);
              border-bottom: 2px solid themed(theme-third-color);
            }
          }
          &.selected{
            @include themify($themes){
              border-bottom: 2px solid themed(theme-primary-color);
            }
          }
        }
      }
      &>div:last-child{
        padding: 12px;
      }
    }
    .contentSection{
        display: flex;
        &>div{
            flex: 1;
        }
      th, td{
        padding: 0px !important;
      }
      th{
        height: 45px;
      }
  
      .firstCellStyle{
        width: 250px !important;
      }
      .categoryWrapper{
        display: flex;
        height: 45px;
        justify-content: center;
        align-items: center;
      }
      .pankouContentWarpper{
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        &>div{
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .ant-input{
        width: 70px;
      }
    }
  
}