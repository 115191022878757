@import '../../../scss/_web_base';

.LotteryStyleWrapper {
  @include themify($themes) {
    font-family: themed(font-family);
  }

  color:#5a6167 !important;

  .operationBtn {
    width: 70px;
    text-align: center;
    border-radius: 5px;
    padding: 7px 15px;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    color: #FFF;
    background-color: #F56C6C;
    border-color: #F56C6C;

    &:hover {
      background: #f78989 !important;
      border-color: #f78989 !important;
    }
  }

}