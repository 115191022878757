@import '../../../../scss//web_base';

.ThirdPartyRebateWrapper{
    background-color: $white;
    width: 100%;
    font-size: 12px;
    @include themify($themes){
      font-family: themed(font-family);
    }
    .inputStyle{
      width: 120px !important;
    }

    .button {
      cursor: pointer;
      color: $white;
      font-weight: 700;
      border-radius: 5px;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.find{
        background: #f56f62;
        border: 1px solid #f56f62;
      }
      &.generate{
        background-color: #E6A23C;
        border-color: #E6A23C;
      }
      &.handle{
        background-color: #409EFF;
        border-color: #409EFF;
      }
    }

    .lotteryMenu  {
      min-width: 140px !important;
      width: 140px;
      width: auto;
      border: 1px solid rgb(220,220,220);
      border-radius: 3px;
      padding: 8px 20px;
      margin-right: 10px !important;
      @include themify($themes){
        border: 1px solid themed(table-border-color);
        background-color: themed(lottery-menu-color);
      }
    }

    .bigWrapper {
      box-shadow: 0 1px 2px rgba(0,0,0,0.15);
      transition: box-shadow 0.3s ease-in-out;
      border-radius: 5px;
      border: 1px solid #ddd;
      width: 100%;
    }

    .operationWrapper {
      border-radius: 3px;
      border: 1px solid #dcdcdc;
        width: 100%;
        @include themify($themes){
          border: 1px solid themed(table-border-color);
          background-color: themed(theme-third-color);
        }
      }
}