@import '../../../scss//web_base';

.handlerLogWrapper {
  position: relative;

  .noWrapStyle{
    white-space: nowrap;
    padding: 0px 20px;
  }
  .oldValueStyle,.newValueStyle{
    max-width: 300px;
    text-align: left;
  }
}