@import '../../../scss/web_base';

.CommonHeaderWrapper {
  color:white;
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  @include themify($themes){
      background-color: themed(theme-primary-color);
  }
  .NotificationWrapper {
    flex:none !important;
  }
  .refreshButton {
    text-align: center;
    border-radius: 5px;
    height: 30px;
    width: 55px;
    line-height: 30px;
    color: white;
    cursor: pointer;
    @include themify($themes){
        background-color: themed(search-button-color);
    }
    &.select {
      background-color: #67C23A;
    }
    &.deselect {
      background-color: #E6A23C;
    }
}
}