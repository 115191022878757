@import '../../../scss//web_base';

.FollowBetLogsWrapper {
  position: relative;

  .backIcon{
    cursor: pointer;
  }

  .button {
    cursor: pointer;
    color: $white;
    border-radius: 5px;
    outline: none;
    font-size: 12px;
    position: relative;
    padding: 3px 5px;
    box-shadow: none;
    &.find{
      background: #f56f62;
      border: 1px solid #f56f62;
    }
    &.handle{
      background-color: #409EFF;
      border-color: #409EFF;
    }
    &.green{
      background-color: #67C23A;
      border-color: #67C23A;
    }
    &.add{
      background: rgb(249, 108, 65);
      border: 1px solid #f56f62;
    }
  }
  .bigWrapper {
    box-shadow: 0 0px 10px rgba(0,0,0,0.15);
    transition: box-shadow 0.3s ease-in-out;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: 100%;
  }
}