@import '../../../../scss//web_base';

.SubAccAddEditWrapper {
  position: relative;

  // .w-all-checkbox {
  //   width: 100px;
  // }
  .w-single-checkbox {
    width: 160px;
  }

  .noWrapStyle {
    white-space: nowrap;
    padding: 0px 20px;
  }

  .oldValueStyle,
  .newValueStyle {
    max-width: 300px;
    text-align: left;
  }

  .form-check-input[type=checkbox] {
    border-radius: 0.15em;
  }

  .el-input__inner {
    font-size: 12px;
    height: 30px;
  }

  .button {
    color: $white;
    font-weight: 700;
    border-radius: 5px;
    outline: none;
    font-size: 12px;
    position: relative;
    padding: 5px 15px 5px 15px;
    box-shadow: none;

    &.find {
      background: #f56f62;
      border: 1px solid #f56f62;
    }

    &.handle {
      background-color: #409EFF;
      border-color: #409EFF;
    }
  }

  .bigWrapper {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.3s ease-in-out;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: 100%;

    input[type=text] {
      height: 30px;
      width: 120px;
      font-size: 12px;
      padding: 5px 8px 5px 8px;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
      width: 60px !important;
    }
  }

  .titleWrapper {
    border-bottom: 1px solid #dcdcdc;
    color: #5d6d7b;
    font-size: 14px;
    font-weight: bold;
    padding: 15px 15px 15px 20px;
    display: flex;
    justify-content: center;

    @include themify($themes) {
      background-color: themed(theme-third-color);
    }
  }

  td,
  th {
    color: #5d6d7b;
    font-size: 12px;
    font-weight: normal;
    padding: 6px 8px;
    margin: 0 auto;

    @include themify($themes) {
      border: 1px solid themed(table-border-color);
    }

    vertical-align: middle;
  }

  .tableHeader {
    @include themify($themes) {
      background-color: themed(theme-third-color);
      color: themed(table-header-text-color);
    }

    font-weight: normal;
    text-align: right;
  }

  tbody {
    tr {
      .topLeftBorderNone {
        border-top: none !important;
        border-left: none !important;
      }
    }
  }

  .oneCharCol {
    width: 50px;
  }

  th {
    font-weight: normal !important;
  }
}