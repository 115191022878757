@import '../../../scss/web_base';

.orderInformationWrapper{
    width: 100%;
    .operationBtn{
        border-radius: 5px;
        padding: 7px 10px;
        line-height: 1;
        white-space: nowrap;
        cursor: pointer;
        color: #FFF;
        @include themify($themes){
          background: themed(theme-primary-color);
          border-color:themed(theme-primary-color);
        }
        &:hover{
            @include themify($themes){
                background: themed(theme-secondary-color);
                border-color:themed(theme-secondary-color);
              }
        }
    
        &.editAndSave{
          @include themify($themes){
              background: themed(theme-primary-color);
              border-color:themed(theme-primary-color);
            }
          &:hover{
              @include themify($themes){
                  background: themed(theme-secondary-color);
                  border-color:themed(theme-secondary-color);
                }
          }
      }
      &.cancel, &.editAndSave{
        padding: 10px 15px !important;
      }
    
        &.add, &.cancel{
          background-color: #F56C6C;
          border-color: #F56C6C;
          &:hover{
              background: #f78989 !important;
              border-color: #f78989 !important;
          }
          .plus_icon{
            margin-right: 3px;
            border-radius: 50%;
            padding: 0px 2px;
            display: inline;
            background-color: white !important;
          .el-icon-plus{
            color: #F56C6C;
          }
        }
        }
      }
    .orderInformationTable{
        border: 1px solid #ddd;
        .el-input{
            width: 100px;
            // margin-left: 5px;
          }
  
          .el-input__inner{
            height: 30px !important;
          }
     
          .el-select-dropdown__item.selected,
          .el-select-dropdown__item.selected.hover {
            font-weight: 700;
            @include themify($themes){
              color: themed(theme-primary-color);
              background-color: themed(drop-down-hover);
            }
          }
  
          .el-select {
            .el-input__inner:hover, .el-input__inner:focus {
              @include themify($themes){
                border-color: themed(theme-primary-color);  
                background-color: themed(drop-down-hover);
              }
            }
            .el-input__inner {      
              width: 100px;
              padding-right: 10px;
              .is-active {
              @include themify($themes){
                color: themed(theme-primary-color);
                border-color:themed(theme-primary-color);
              }
            }
            }   
          }  
          .el-select-dropdown__item:hover {
            @include themify($themes){
              background-color: themed(drop-down-hover);
            }
          }
          .el-select-dropdown {
            border-radius: 4px !important;
          }
        .evenRowStyle{
            display: flex;
            &>div:nth-child(odd){
              @include themify($themes){
                background-color: themed(theme-third-color);
              }
                width: 15%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-right: 20px;
            }
            &>div:nth-child(even){
                flex: 1;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding-left: 20px;
                &.oneKeyOutMoney{
                  white-space: nowrap;
                  overflow-x: scroll;
                  padding: 0px 20px;
                  &::-webkit-scrollbar {
                    height: 5px;
                    border-radius: 5px;
                  }
                  &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                  }
                  &::-webkit-scrollbar-thumb {
                    background: rgb(212, 212, 212);
                    border-radius: 5px;
                  }
                  &::-webkit-scrollbar-thumb:hover {
                    background: rgb(204, 204, 204);
                  }
                }
            }
            &>div:not(:last-child){
                border-right: 1px solid #ddd;
            }
        }
        &>div{
            min-height: 40px;
        }
        &>div:not(:last-child){
            border-bottom: 1px solid #ddd;
        }
        .orderInformationRowBackGroundColor{
          @include themify($themes){
            background-color: themed(theme-third-color);
          }
        }
    }
    
}
