@import '../../scss/web_base';

.betRevokeWrapper{
    
    height: max-content;
    display: flex;
    background-color: $white;
    border-radius: 5px;
    flex-direction: column;
    min-width: 975px;
    width: 100%;
    @include themify($themes){
      font-family: themed(font-family);
    }
    .menuSelectedText {
      width: 90px !important;
    }
    .subselection {
      position:absolute;
      top:5;
      z-index:1;
    }
    .hide {
      display: none;
    }
    .menuWrapper {
      background: white;
      height: 30px;
      width: 120px !important;
      font-size: 12px;
      text-align: left;
      border-radius: 3px;
      padding: 5px 10px 5px 10px;
      color:black;
    }

    .filterWrapper {
      font-size: 12px !important;
      &>label{
        margin: 0px 2px;
      }
      .datePickRangeStyle{
        width: 220px;
      }
      .inputSearchStyle{
        width: 85px;
      }
      .selectStyle{
        &.installment{
          width: 190px;
        }
        &.betType{
          width: 95px;
        }
        &.category{
          width: 125px;
        }
        &.betStatus{
          width: 76px;
        }
        &.searchType{
          width: 88px;
        }
      }

      border-radius: 3px;
      color: white;
      border: 1px solid #dcdcdc;
        @include themify($themes){
            background-color: themed(theme-primary-color);
        }
        .searchButton {
          text-align: center;
          cursor: pointer;
          color: $white;
          @include themify($themes) {
            background: themed(search-button-color);
            border: 1px solid themed(search-button-color);
          }
          border-radius: 5px;
          padding: 5px;
          &:hover {
            @include themify($themes) {
              background: themed(search-button-color);
              border: 1px solid themed(search-button-color);
            }
          }
        }
      }
}