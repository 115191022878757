@import '../../scss/web_base';

    .loginAndRegister{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        input:-webkit-autofill,
        input:-webkit-autofill:focus {
          transition: background-color 2147483647s;
        }
        .loginBox{
            box-shadow: 0px 3px 6px #00000029;
            width: 446px;
            background-color: white;
            padding: 30px 40px;
            border-radius: 20px;
            .loginTitle{
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                @include themify($themes){
                    color: themed(theme-secondary-color);
                }
                font-weight: bold;
                margin-top: 10px;
                margin-bottom: 30px;
            }
            .loginBoxItem{
                margin-bottom: 20px;
                height: 45px;
                border-radius: 30px;
                width: 100%;
                border: 1px solid #ccc;
                display: flex;
                align-items: center;

                &.mfaOpen{
                    transition: height 0.4s;
                    height: 45px;
                }
                &.mfaClosed{
                    margin-bottom: 0px !important;
                    transition: all 0.4s;
                    height: 0px;
                    border: none;
                    overflow: hidden;
                }

                .loginButton{
                    @include themify($themes){
                        background-color: themed(theme-primary-color);
                    }
                    &:hover{
                        @include themify($themes){
                            background-color: themed(theme-secondary-color) !important;
                        }
                    }
                    height: 45px !important;
                    width: 100%;
                    border-radius: 30px;
                    border: none !important;
                    font-weight: bold;
                }
                &>div:first-child{
                    font-weight: bold;
                    padding-left: 20px;
                    @include themify($themes){
                        color: themed(theme-primary-color);
                    }
                    width: 30%;
                }
                input{
                    flex: 1;
                    border: none !important;
                    background-color: transparent !important;
                    &:focus{
                        box-shadow: none !important;
                    }
                }
                #captchaImg{
                    height: 43px !important;
                    border-top-right-radius: 30px;
                    border-bottom-right-radius: 30px;
                }
            }
        }
    }
    .loginRegisterWarpper{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;  
        &.Web{ 
        background: url("../../assets/image/login_newyear.png") no-repeat center; 
        }
        &.Mobile{
            background: url("../../assets/image/login_newyearm.png") no-repeat center; 
        }
        background-size:cover;
        background-attachment: fixed;
    }

    .mobileLogin{
        height: 100%;
        margin: 0;

        .mobileLoginBoxItem{
            height: 62px;
            &.mfaOpen{
                transition: height 0.5s;
                height: 62px;
            }
            &.mfaClosed{
                transition: all 0.5s;
                height: 0px;
                border: none;
                overflow: hidden;
            }
        }

        .loginConfirmBtn{
            @include themify($themes) {
                background-color: themed(theme-primary-color);
            }
            border: none;
        }
        .labelLine {
            @include themify($themes) {
                background-color: themed(theme-primary-color);
                color: transparent;
            }
            border-radius: 30%;
            margin-right: .3rem;
        }
        .loginLabelText{
            @include themify($themes) {
                color: themed(withdrawal-form-label-color);
            }
        }

        .loginTitle{
            font-weight: 700;
            @include themify($themes) {
                color: themed(theme-primary-color);
            }
            font-size: 16px;
        }

        .loginMFA {
            @include themify($themes) {
                background-color: themed(theme-primary-color);
            }
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            padding: 0px 10px;
            color: $white;

        }
    }
    