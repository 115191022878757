$white: #FFFFFF;
$black: #000000;
$grey: #808080;
$red: #FF0000;
$fontFamily: Tahoma, Arial, 'Hiragino Sans GB W3', '\5b8b\4f53', Sans-Serif;
$ballRed: #EC6941;
$ballBlue: #5EA1F7;
$ballGreen: #80C269;
$ballYellow: #F5E204;
$place-holder-text-color: #CECECE;
$fanTanTopBottom: #CBE6EC;
$fanTanLeftRight: #FFE658;
$fanTanCorner: #FFE69B;
$border: #DCDCDC;
$themes: (
    blue:(
        font-family: $fontFamily,
        theme-primary-color: #448EED,
        theme-secondary-color: #3D85DF,
        theme-third-color: #EAF3FF,
        header-selected-menu: #E8F1FB,
        sub-game-menu-text-color: #677683,
        sub-game-active-game-text-color: #FF5400,
        data-table-text-color: #5D6D7B,
        settled-unsettled-row-no:#1E70DC,
        settled-unsettled-installment:#81C269,
        settled-unsettled-bet-content:#5FA1F6,
        menu-amount:#F96C41,
        settled-unsettled-win-money:#5FA1F6,
        settled-unsettled-lose-money:#F96C41,
        table-hover:#ffffe4,
        records-sum-positive-text-color: #5FA1F6,
        records-sum-negative-text-color: #FF001D,
        table-border-color:#DCDCDC,
        table-header-text-color:#5D6D7B,
        reset-button-color:#8CC878,
        table-cell-color:#F7F7F7, 
        search-button-color: #f56f62,
        search-button-hover-color: #f78989,
        notice-color: #f34646,
        drop-down-hover: #F5F7FA,
        button-color: #6eaeff,
        theme-lighter-color: #409EFF,
        small-button-color: #5ea0f7,
        small-button-hover: #499449,
        odds-color:#f70824,
        win-lost:#80c269,
        in-time-selected:#fff694,
        table-square-1:#FFCE00,
        table-square-2:#2C39CA,
        table-square-3:#6F6A66,
        table-square-4:#FF9837,
        table-square-5:#2DCEDE,
        table-square-6:#7655B9,
        table-square-7:#A9A9A9,
        table-square-8:#F54141,
        table-square-9:#792020,
        table-square-10:#4CC32D,
        installment-text:#ffdc1c,
        notice-text-color: #f56c6c,
        lottery-menu-color: #fff5e9,
        result-red-text:#F96C41,
        result-green-text:#81C269,
        result-blue-text: #5FA1F6,
    ),
    red:(
        font-family: $fontFamily,
        theme-primary-color: #b44457,
        theme-secondary-color: #C87583,
        theme-third-color: #ffefef,
        header-selected-menu: #fff1f1,
        sub-game-menu-text-color: #677683,
        sub-game-active-game-text-color: #FF5400,
        data-table-text-color: #5D6D7B,
        settled-unsettled-row-no:#1E70DC,
        settled-unsettled-installment:#81C269,
        settled-unsettled-bet-content:#5FA1F6,
        menu-amount:#F96C41,
        settled-unsettled-win-money:#5FA1F6,
        settled-unsettled-lose-money:#F96C41,
        table-hover:#ffffe4,
        records-sum-positive-text-color: #5FA1F6,
        records-sum-negative-text-color: #FF001D,
        table-border-color:#DCDCDC,
        table-header-text-color:#5D6D7B,
        reset-button-color:#8CC878,
        table-cell-color:#F7F7F7,
        search-button-color: #f56f62, 
        search-button-hover-color: #f78989,
        notice-color: #f34646,
        drop-down-hover: #F5F7FA,
        button-color: #6eaeff,
        theme-lighter-color: #409EFF,
        small-button-color: #5ea0f7,
        odds-color:#f70824,
        win-lost:#80c269,
        in-time-selected:#fff694,
        table-square-1:#FFCE00,
        table-square-2:#2C39CA,
        table-square-3:#6F6A66,
        table-square-4:#FF9837,
        table-square-5:#2DCEDE,
        table-square-6:#7655B9,
        table-square-7:#A9A9A9,
        table-square-8:#F54141,
        table-square-9:#792020,
        table-square-10:#4CC32D,
        installment-text:#ffdc1c,
        notice-text-color: #f56c6c,
        lottery-menu-color: #fff5e9,
        result-red-text:#F96C41,
        result-green-text:#81C269,
        result-blue-text: #5FA1F6,

    ),
    green:(
        font-family: $fontFamily,
        theme-primary-color: #60990a,
        theme-secondary-color: #8BB25C,
        theme-third-color: #f6ffe9,
        header-selected-menu: #f1f7e8,
        sub-game-menu-text-color: #677683,
        sub-game-active-game-text-color: #FF5400,
        data-table-text-color: #5D6D7B,
        settled-unsettled-row-no:#1E70DC,
        settled-unsettled-installment:#81C269,
        settled-unsettled-bet-content:#5FA1F6,
        menu-amount:#F96C41,
        settled-unsettled-win-money:#5FA1F6,
        settled-unsettled-lose-money:#F96C41,
        table-hover:#ffffe4,
        records-sum-positive-text-color: #5FA1F6,
        records-sum-negative-text-color: #FF001D,
        table-border-color:#DCDCDC,
        table-header-text-color:#5D6D7B,
        reset-button-color:#8CC878,
        table-cell-color:#F7F7F7, 
        search-button-color: #f56f62,
        search-button-hover-color: #f78989,
        notice-color: #f34646,
        drop-down-hover: #F5F7FA,
        button-color: #6eaeff,
        theme-lighter-color: #409EFF,
        small-button-color: #5ea0f7,
        odds-color:#f70824,
        win-lost:#80c269,
        in-time-selected:#fff694,
        table-square-1:#FFCE00,
        table-square-2:#2C39CA,
        table-square-3:#6F6A66,
        table-square-4:#FF9837,
        table-square-5:#2DCEDE,
        table-square-6:#7655B9,
        table-square-7:#A9A9A9,
        table-square-8:#F54141,
        table-square-9:#792020,
        table-square-10:#4CC32D,
        installment-text:#ffdc1c,
        notice-text-color: #f56c6c,
        lottery-menu-color: #fff5e9,

    ),
    yellow:(
        font-family: $fontFamily,
        theme-primary-color: #d79a18,
        theme-secondary-color: #DD9B11,
        theme-third-color: #f9f6eb,
        header-selected-menu: #f7f1e6,
        sub-game-menu-text-color: #677683,
        sub-game-active-game-text-color: #FF5400,
        data-table-text-color: #5D6D7B,
        settled-unsettled-row-no:#1E70DC,
        settled-unsettled-installment:#81C269,
        settled-unsettled-bet-content:#5FA1F6,
        menu-amount:#F96C41,
        settled-unsettled-win-money:#5FA1F6,
        settled-unsettled-lose-money:#F96C41,
        table-hover:#ffffe4,
        records-sum-positive-text-color: #5FA1F6,
        records-sum-negative-text-color: #FF001D,
        table-border-color:#DCDCDC,
        table-header-text-color:#5D6D7B,
        reset-button-color:#8CC878,
        table-cell-color:#F7F7F7, 
        search-button-color: #f56f62,
        search-button-hover-color: #f78989,
        notice-color: #f34646,
        drop-down-hover: #F5F7FA,
        button-color: #6eaeff,
        theme-lighter-color: #409EFF,
        small-button-color: #5ea0f7,
        odds-color:#f70824,
        win-lost:#80c269,
        in-time-selected:#fff694,
        table-square-1:#FFCE00,
        table-square-2:#2C39CA,
        table-square-3:#6F6A66,
        table-square-4:#FF9837,
        table-square-5:#2DCEDE,
        table-square-6:#7655B9,
        table-square-7:#A9A9A9,
        table-square-8:#F54141,
        table-square-9:#792020,
        table-square-10:#4CC32D,
        installment-text:#ffdc1c,
        notice-text-color: #f56c6c,
        lottery-menu-color: #fff5e9,

    )
);

@mixin themify($themes) {
    @each $theme, $map in $themes {
      .theme-#{$theme} & {
        $theme-map: () !global;
        @each $key, $submap in $map {
          $value: map-get(map-get($themes, $theme), '#{$key}');
          $theme-map: map-merge($theme-map, ($key: $value)) !global;
        }
        @content;
        $theme-map: null !global;
      }
    }
  }
  
  @function themed($key) {
    @return map-get($theme-map, $key);
  }