@import '../../../scss/web_base';

.presetAmountWrapper {
  width: 550px;
  .modalHeader {
    display: flex;
    justify-content: space-between;
    height: 40px;
    padding: 0;
    color: $white;
    font-size: 16px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
    }
    .closeIcon {
      cursor: pointer;
    }
  }
  .modalContent {
    background-color: $white;
    padding: 30px 20px;
    display: flex;
    justify-content: space-between;
    input {
      height: 30px;
      text-align: center;
      font-size: 13px;
      box-shadow: none;
      cursor: pointer;
      color: $black;
      &:focus {
        @include themify($themes) {
          border: 1px solid themed(theme-primary-color);
        }
        & + .crossIconWrapper {
          display: flex;
        }
      }
    }
    .crossIconWrapper {
      display: none;
      @include themify($themes) {
        border: 1px solid themed(theme-primary-color);
      }
      background-color: $white;
      padding: 1px;
      position: absolute;
      top: -10px;
      right: -2px;
      border-radius: 50%;
      width: 23px;
      height: 23px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .crossIcon {
        width: 13px;
        height: 13px;
      }
    }
  }
  .modalFooter {
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include themify($themes) {
      border-top: 1px solid themed(table-border-color);
    }
    background-color: $white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    .resetDefaultWrapper {
      @include themify($themes) {
        color: themed(reset-default-text-color);
      }
      .radiobutton {
        @include themify($themes) {
          stroke: themed(reset-default-text-color);
          fill: themed(reset-default-text-color);
        }
        cursor: pointer;
      }
      .resetDefaultText {
        cursor: pointer;
      }
    }
    .saveBtn {
      width: 84px;
      height: 28px;
      color: $white;
      border-radius: 5px;
      border: 0;
      outline: none;
      font-size: 12px;
      font-weight: bold;
      margin-left: 15px;
      @include themify($themes) {
        border: 1px solid themed(theme-primary-color);
        background-color: themed(theme-primary-color);
      }
    }
  }
}
