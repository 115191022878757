@import '../../scss/web_base';

.lotteryMenuWrapper {
    font-size: 12px;
    display: flex;
    position: relative;
    background-color: white;
    border-radius: 3px;
    border:1px solid #dcdcdc;
    padding: 12px;
    .menuSection{
        margin: 0px 13px;
        width: 106px;
        .title{
            color: #666666;

        }
        .gapLine{
            width: 100%;
            margin-bottom: 5px;
            margin-top: 2px;
            border-bottom: 1px solid #cccccc;
        }
        .content{
            .lotteryName{
                white-space: nowrap;
                padding: 5px 0px;
                cursor: pointer;
                color: #838383;
                &:hover, &.selected{
                    font-weight: bold !important;
                    @include themify($themes){
                        color: themed(theme-primary-color) !important;
                    }
 
                }
            }
        }

        
    }
}