@import '../../../scss/web_base';

.changePasswordModalWrapper {
  width: 550px;
  @include themify($themes) {
    font-family: themed(font-family);
  }
  .modalHeader {
    line-height: 45px;
    color: $white;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
      font-family: themed(font-family);
    }
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
    .closeIcon {
      cursor: pointer;
    }
  }
  .modalContent {
    padding: 20px 40px 0px 40px;
    font-size: 16px;
    .errorMsg{
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .loginBoxItem{
      margin-top: 20px;
      height: 45px;
      border-radius: 30px;
      width: 100%;
      border: 1px solid #ccc;
      display: flex;
      align-items: center;
      .loginButton{
          @include themify($themes){
              background-color: themed(theme-primary-color);
          }
          &:hover{
              @include themify($themes){
                  background-color: themed(theme-secondary-color) !important;
              }
          }
          height: 45px !important;
          width: 100%;
          border-radius: 30px;
          border: none !important;
          font-weight: bold;
      }
      &>div:first-child{
          font-weight: bold;
          padding-left: 20px;
          @include themify($themes){
              color: themed(theme-primary-color);
          }
          width: 30%;
      }
      input{
          flex: 1;
          border: none !important;
          background-color: transparent !important;
          &:focus{
              box-shadow: none !important;
          }
      }
      #captchaImg{
          height: 43px !important;
          border-top-right-radius: 30px;
          border-bottom-right-radius: 30px;
      }
  }
  }
  .modalFooter {
    padding: 20px 40px;
    font-size: 16px;
    height: max-content;
    margin: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include themify($themes) {
      border-top: 1px solid #ccc
    }
    .confirmButton {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 120px;
      height: 36px;
      color: $white;
      border-radius: 4px;
      font-size: 16px;
      text-align: center;
      @include themify($themes){
        background-color: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
    }
  }
}