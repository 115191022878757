@import '../../../scss/_web_base';

.winLostReportWrapper{
    font-size: 12px;
   .tabWrapper{
    display: flex;
    padding: 0px 20px;
    &>div{
      padding: 0px 10px;
      &:hover{
        cursor: pointer;
        @include themify($themes){
          color: themed(theme-primary-color);
        }
      }
      &.selected::after,&:hover::after{
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        @include themify($themes){
          background-color: themed(theme-primary-color);
        }
        margin-top: 5px;
        box-sizing: content-box;
      }
    }
   }
}