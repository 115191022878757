@import '../../../scss//web_base';

.betHandleRebateWrapper{
    color: #5d6d7b;
    height: max-content;
    display: flex;
    background-color: $white;
    border-radius: 5px;
    flex-direction: column;
    width: 100%;
    @include themify($themes){
      font-family: themed(font-family);
    }

    .hide {
      display: none;
    }

    .filterWrapper {
      color: #5d6d7b;
      font-size: 12px;
      border-radius: 3px;
      border: 1px solid #dcdcdc;
        @include themify($themes){
            background-color: themed(theme-third-color);
        }
        .rebetaInputStyle{
          width: 120px;
        }

        .searchButton {
          color: $white;
          font-weight: 700;
          border-radius: 5px;
          outline: none;
          font-size: 12px;
          position: relative;
          padding: 5px 15px 5px 15px;
          box-shadow: none;

          &.find{
            background: #f56f62;
            border: 1px solid #f56f62;
          }
          &.generate{
            background-color: #E6A23C;
            border-color: #E6A23C;
          }
          &.handle{
            background-color: #409EFF;
            border-color: #409EFF;
          }
        }
        .dropdownred {
          color:white;
            .el-input__inner:hover, .el-input__inner:focus {
              @include themify($themes){
                border-color: themed(theme-primary-color);  
                background-color: themed(search-button-color);
              }
            }
            .el-input__inner {      
              color: white;
              font-weight: 700;
              @include themify($themes) {
                background: themed(search-button-color);
                border: 1px solid themed(search-button-color);
              }
            }
            ::placeholder {
              color: white;
            }
          }

      }
}