@import '../../scss/web_base';

.subSelectionWrapper {
    display: flex;
    width: 100%;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    margin-bottom: 10px;
    @include themify($themes){
        border: 1px solid themed(table-border-color);
    }
    padding: 6px 0px;

    &>div:not(:last-child){
        @include themify($themes){
            border-right: 1px solid themed(table-border-color);
        }
    }

    .menuItem{
        cursor: pointer;
        padding: 0px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        .menuItemTitle{
            &.selected{
                @include themify($themes){
                    font-weight: bold !important;
                    color: themed(theme-primary-color) !important;
                }
            }
        }
        .menuAmount{
            margin-left: 10px;
            text-align: center;
            @include themify($themes){
                color: themed(menu-amount);
            }
        }
    }
}