@import '../../../scss/web_base';

.operationBtn {
  border-radius: 5px;
  padding: 7px 15px;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  color: #FFF !important;


  &:hover {
    color: white !important;
  }

  &.edit {
    background-color: #06438f;
    border-color: #06438f;

    &:hover {
      background-color: #06438f !important;
      border-color: #06438f !important;
    }
  }

  &.editAndSave {
    background-color: #448eed;
    border-color: #448eed;

    &:hover {
      background-color: #448eed !important;
      border-color: #448eed !important;
    }
  }

  &.add {
    background-color: #67C23A;
    border-color: #67C23A;

    &:hover {
      background: #85ce61 !important;
      border-color: #85ce61 !important;
    }
  }

  &.remove {
    background-color: #F56C6C;
    border-color: #F56C6C;

    &:hover {
      background: #f78989 !important;
      border-color: #f78989 !important;
    }
  }
}

.DirectMemberWrapper1 {
  height: max-content;
  display: flex;
  background-color: $white;
  border-radius: 5px;
  flex-direction: column;
  min-width: 975px;
  width: 100%;

  .w-max {
    width: max-content;
  }

  .whiteListMenu {
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    padding-left: 25px;

    .menu_item {
      cursor: pointer;
      margin: 0px 5px;
      padding: 3px 0px;
      border-bottom: 2px solid transparent;

      &.selected {
        font-weight: bold;

        @include themify($themes) {
          border-bottom: 2px solid themed(theme-primary-color);
        }
      }

      &:hover {
        @include themify($themes) {
          border-bottom: 2px solid themed(theme-primary-color);
          color: themed(theme-primary-color);
        }
      }
    }
  }

  .operationBtn {
    border-radius: 5px;
    padding: 7px 15px;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    color: #FFF;

    &.editAndSave {
      background-color: #448eed;
      border-color: #448eed;

      &:hover {
        background-color: #448eed !important;
        border-color: #448eed !important;
      }
    }

    &.add {
      background-color: #67C23A;
      border-color: #67C23A;

      &:hover {
        background: #85ce61 !important;
        border-color: #85ce61 !important;
      }
    }

    &.remove {
      background-color: #F56C6C;
      border-color: #F56C6C;

      &:hover {
        background: #f78989 !important;
        border-color: #f78989 !important;
      }
    }
  }

  @include themify($themes) {
    font-family: themed(font-family);
  }

  .w-input-100 {
    width: 100px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .filterArea {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .menuSelectedText {
    width: 90px !important;
  }

  .smallicon {
    font-size: 10px;
  }

  .subselection {
    position: absolute;
    top: 5;
    z-index: 1;
  }

  .el-popover {
    width: 180px !important;
    padding: 7px;
  }

  .hide {
    display: none;
  }

  .marginLeft5 {
    margin-left: 1.2em;
  }

  .menuWrapper {
    background: white;
    height: 30px;
    width: 120px !important;
    font-size: 12px;
    text-align: left;
    border-radius: 3px;
    padding: 5px 10px 5px 10px;
    color: black;
  }

  .bellNoti {
    font-size: 1rem;
    line-height: 0;
  }

  .bellNotiCircle {
    font-size: 1rem;
    line-height: 0;

    @include themify($themes) {
      background-color: themed(notice-color);
    }

    padding: 7px;
    border-radius: 50%;
    display:block;
  }

  .subMenu {
    border: 1px solid rgb(220, 220, 220);
    border-radius: 3px;
    width: 100%;
    padding: 8px;

    @include themify($themes) {
      border: 1px solid themed(table-border-color);
      background-color: themed(theme-third-color);
    }
  }

  .addButton {
    color: $white;
    font-weight: 300;

    @include themify($themes) {
      background: themed(menu-amount);
      border: 1px solid themed(menu-amount);
    }

    border-radius: 5px;
    outline: none;
    font-size: 11.4px;
    position: relative;
    padding: 2px 9px 2px 5px;
    box-shadow: none;

    &:hover {
      @include themify($themes) {
        background: themed(menu-amount);
        border: 1px solid themed(menu-amount);
      }
    }
  }

  .filterWrapper {
    color: white;
    font-size: 12px;
    border-radius: 3px;
    border: 1px solid #dcdcdc;

    @include themify($themes) {
      background-color: themed(theme-primary-color);
    }

    .form-control {
      width: 17%;
    }

    .betQueryDatepicker {
      width: 185px !important;
    }

    .searchButton {
      color: $white;
      font-weight: 700;

      @include themify($themes) {
        background: themed(search-button-color);
        border: 1px solid themed(search-button-color);
      }

      border-radius: 5px;
      outline: none;
      font-size: 12px;
      position: relative;
      padding: 5px 15px 5px 15px;
      box-shadow: none;

      &:hover {
        @include themify($themes) {
          background: themed(search-button-color);
          border: 1px solid themed(search-button-color);
        }
      }
    }
  }

  .popButton {
    .btn {
      color: $white;
      border-radius: 2px;
      outline: none;
      font-size: 11.5px !important;
      position: relative;
      padding: 1px 9px 1px 9px !important;
      box-shadow: none;
    }

    .idle {
      @include themify($themes) {
        border: 1px solid themed(theme-primary-color);
        background-color: themed(theme-primary-color);
      }
    }

    .active {
      @include themify($themes) {
        border: 1px solid themed(table-border-color);
        background-color: themed(table-border-color);
      }
    }
  }
}

.breadcrumbList {
  .mainitem {
    padding: 3px;
    cursor: pointer !important;
    // border-radius: 15%;
    // background-color: #f9fbff;

    &.active {
      @include themify($themes) {
        color: themed(theme-primary-color);
        border-bottom: 1px solid themed(table-border-color);
      }
    }
  }


  .delimiter {
    font-size: 14px;

    @include themify($themes) {
      color: themed(table-border-color);
    }
  }
}