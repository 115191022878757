@import '../../../scss/_web_base';


.QuickBetMonitorWrapper {
  font-size: 12px;
  color: #5d6d7b;
  position: relative;

  .w-120 {
    width: 120px;
  }

  .info_tishi {
    color: #808080
  }

  .minxi {
    color: '#0000ff';
    cursor: pointer;

    &:hover {
      color: red;
    }
  }

  .marginLeft1 {
    margin-left: 4px;
  }

  .betWarningInput {
    width: 50%;
    max-width: 120px;
  }

  // copy from UserManagement component AgentList className： DirectAgentWrapper  
  .QuickBetMonitorFilterWrapper {
    height: max-content;
    display: flex;
    background-color: $white;
    border-radius: 5px;
    flex-direction: column;
    min-width: 975px;
    width: 100%;

    @include themify($themes) {
      font-family: themed(font-family);
    }

    .menuSelectedText {
      width: 90px !important;
    }

    .smallicon {
      font-size: 10px;
    }

    .subselection {
      position: absolute;
      top: 5;
      z-index: 1;
    }

    .hide {
      display: none;
    }

    .marginLeft5 {
      margin-left: 1.2em;
    }

    .filterWrapper {
      color: white;
      font-size: 12px;
      border-radius: 3px;
      border: 1px solid #dcdcdc;

      @include themify($themes) {
        background-color: themed(theme-primary-color);
      }

      input {
        height: 30px;
        width: 95px !important;
        font-size: 12px;
        padding: 5px 8px 5px 8px;
      }

      input[type=number] {
        width: 60px !important;
      }

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type=number] {
        -moz-appearance: textfield;
      }

      .form-control {
        width: 17%;
      }

      .searchButton {
        color: $white;
        font-weight: 700;

        @include themify($themes) {
          background: themed(search-button-color);
          border: 1px solid themed(search-button-color);
        }

        border-radius: 5px;
        outline: none;
        font-size: 12px;
        position: relative;
        padding: 5px 15px 5px 15px;
        box-shadow: none;

        &:hover {
          @include themify($themes) {
            background: themed(search-button-color);
            border: 1px solid themed(search-button-color);
          }
        }
      }
    }
  }
}