@import '../../../scss//web_base';

.FollowBetAddEditAddEditWrapper {
  position: relative;

  .boxWidth{
    width: 250px !important;
  }

  .ant-checkbox-wrapper{
    min-width: 140px !important;
    margin-left: 0px !important;
  }

  .text-red{
    color: red;
  }

  .backIcon{
    cursor: pointer;
  }

  .button {
    cursor: pointer;
    color: $white;
    border-radius: 5px;
    outline: none;
    font-size: 12px;
    position: relative;
    padding: 3px 5px;
    box-shadow: none;
    &.find{
      background: #f56f62;
      border: 1px solid #f56f62;
    }
    &.handle{
      background-color: #409EFF;
      border-color: #409EFF;
    }
  }

  .textAreaInput {
    .form-control {
      font-size: 12px;
      min-width: 450px;
      max-width: 550px;
      min-height: 100px;
    }
  }

  td,th{
    color: #5d6d7b;
    font-size: 12px;
    font-weight: normal;
    padding: 6px 8px;
    margin: 0 auto;
    @include themify($themes) {
      border: 1px solid themed(table-border-color);
    }
    vertical-align: middle;
  }
  .titleHeader{
    @include themify($themes){
      background-color: themed(theme-third-color);
      color: themed(table-header-text-color);
    }
    font-weight: normal;
    text-align: center;
  }
  .tableHeader{
    @include themify($themes){
      background-color: themed(theme-third-color);
      color: themed(table-header-text-color);
    }
    font-weight: normal;
    text-align: right;
  }
  tbody {
    tr {
      .topLeftBorderNone {
        border-top: none !important;
        border-left: none !important;
      }
    }
  }

}