@import '../../../scss/web_base';

.HistoryDrawManagementWrapper{
    color: #5d6d7b;
    height: max-content;
    display: flex;
    background-color: $white;
    border-radius: 5px;
    flex-direction: column;
    width: 100%;
    .hk6Ball,.gxk10{
      width: 25px;
      height: 25px;
      line-height: 19px;
      text-align: center;
      box-sizing: border-box;
      color: #626262;
      font-size: 10px;
      font-weight: bold;
      border-radius: 50%;
      border: 3px solid;
      padding: 0;
      background-color: #EAF3FF;
  
      &.ball_red{
          border-color: $ballRed !important;
      }
      &.ball_blue{
          border-color: $ballBlue !important;
      }
      &.ball_green{
          border-color: $ballGreen !important;
      }
    }

    .boDuanColor{
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      &.red{
        background-color: #ec6941;
      }
      &.green{
        background-color: #60990a;
      }
      &.blue{
        @include themify($themes){
          background-color: themed(theme-primary-color);
        }
      }
    }

    .composedCell{
      display: flex;
      flex-direction: column;
      height: 100%;
      &>div:first-child{
        border-bottom: 1px solid #ccc;
      }
      &>div{
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .divideCellWithBorder{
      width: 100%;
      display: flex;
      &.subInner{
        &>div{
          display: flex;
          justify-content: center;
          align-items: center;
          &>div{
            height: 100%;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &>div:first-child{
            border-right: 1px solid #ccc;
          }
        }
      }
      &.subSingleInner{
        &>div{
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          &>div:first-child{
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &>div:last-child{
            display: none;
          }
        }
      }
      &>div{
        flex: 1;
      }
      &>div:not(:first-child){
      border-left: 1px solid #ccc;
      }
    }

    .divideCellWithBorderForQXC{
      width: 100%;
      display: flex;
      &>div{
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        height: 100% !important;
      }
      &>div:not(:first-child){
      border-left: 1px solid #ccc;
      }
    }

    .detailLink{
      cursor: pointer;
    }
    @include themify($themes){
      font-family: themed(font-family);
    }
    .menuSelectedText {
      width: 90px !important;
    }
    .subselection {
      position:absolute;
      top:5;
      z-index:1;
    }
    .hide {
      display: none;
    }
    .menuWrapper {
      background: white;
      height: 30px;
      width: 120px !important;
      font-size: 12px;
      text-align: left;
      border-radius: 3px;
      padding: 5px 10px 5px 10px;
      color:black;
    }

    .filterWrapper {
      color: #5d6d7b;
      font-size: 12px;
      border-radius: 3px;
      border: 1px solid #dcdcdc;
        @include themify($themes){
            background-color: themed(theme-third-color);
        }
        .qishuInputStyle{
          width: 250px;
          margin: 0px 5px;
        }
      }
      .searchButton {
        color: $white;
        font-weight: 700;
        border-radius: 5px;
        outline: none;
        font-size: 12px;
        position: relative;
        padding: 5px 15px 5px 15px;
        box-shadow: none;
        cursor: pointer;
        
        background-color: #409EFF;
        border-color: #409EFF;

        &.redBtn{
          background: #f56f62;
          border: 1px solid #f56f62;
        }
      }
      thead{
      tr>th{
        height: 50px !important;
        &>span{
          height: 50px !important;
        }
      }
    }
}