@import '../../../scss/web_base';

.gameManagementWrapper  {
    font-size: 12px;
    color: #5d6d7b;
    position: relative;
    @include themify($themes) {
      font-family: themed('fontFamily');
    }
    .gamesContent{
      display: flex;
      &>div{
        flex: 1;
      }
    }
    .addColumnColor{
      @include themify($themes){
        background-color: themed(theme-third-color);
      }
    }
    .operationBtn{
      border-radius: 5px;
      padding: 7px 10px;
      line-height: 1;
      white-space: nowrap;
      cursor: pointer;
      color: #FFF;
      @include themify($themes){
        background: themed(theme-primary-color);
        border-color:themed(theme-primary-color);
      }
      &:hover{
          @include themify($themes){
              background: themed(theme-secondary-color);
              border-color:themed(theme-secondary-color);
            }
      }
  
      &.reset{
        background-color: #F56C6C;
        border-color: #F56C6C;
        &:hover{
            background: #f78989 !important;
            border-color: #f78989 !important;
        }
      }
    }

    .menuRow{
      padding: 5px 10px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include themify($themes){
        background-color: themed(theme-third-color);
      }

      .betQueryDatepicker {
        .el-input {
          width: 150px !important;
        }
      }
    
      .el-input__inner{
        height: 30px !important;
      }
      .currentSelected{
          border: 1px solid #d2d7e0;
          border-radius: 3px;
          cursor: pointer;
          position: relative;
          width: 120px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          padding: 0;
          background-color: white;
          @include themify($themes){
            color: themed(theme-primary-color);
          }
          &:hover{
            @include themify($themes){
              background-color: themed(theme-primary-color) !important;
            }
              color: white !important;
              .arrow_down{
                  border-top: 5px solid white !important;
              }
          }
          .arrow_down{
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              display: inline-block;
              margin: 2px 10px;
              @include themify($themes){
                border-top:5px solid  themed(theme-primary-color);
              }
          }
          .bogusBetWarningMenuWrapper{
              position: absolute;
              z-index: 2;
          }
      }
  }  
}