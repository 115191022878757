@import '../../../scss/_web_base';

.CompanyGameReportWrapper{
    font-size: 12px;
    color: #5d6d7b;
    position: relative;
    .noWrapStyle{
      white-space: nowrap;
    }

    .gameDetailCell{
      margin: auto;
       width: 250px;
       &>div:first-child{
        text-align: left;
       }
       &>div:last-child{
        display: flex;
        flex-wrap: wrap;
       }
    }

    .CompanyGameReportTableWrapper{
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      margin: 10px 0;
      border-radius: 5px;
      border: 1px solid #ddd;
      background: #ffffff;

      .userTable{
          .accountName{
            width: 125px;
          }
          .operationBtn{
            border-radius: 5px;
            padding: 7px 15px;
            line-height: 1;
            white-space: nowrap;
            cursor: pointer;

            &.dateSelectBtn{
              border: 1px solid #DCDFE6;
              color: #5d6d7b !important;
              &:hover,&.selected{
              color: #409EFF !important;
              border-color: #c6e2ff !important;
              background-color: #ecf5ff !important;
              }
            }
    
            &.findAndCollapse{
              color: #FFF;
                @include themify($themes){
                    background: themed(theme-primary-color);
                    border-color:themed(theme-primary-color);
                  }
                &:hover{
                    @include themify($themes){
                        background: themed(theme-secondary-color);
                        border-color:themed(theme-secondary-color);
                      }
                }
            }
    
            &.cancel{
              color: #FFF;
                background-color: #F56C6C;
                border-color: #F56C6C;
                &:hover{
                    background: #f78989 !important;
                    border-color: #f78989 !important;
                }
            }
          }
        border: 1px solid #ddd;
        &>div{
          height: 40px;
        }

        &>div{
            &:not(:first-child){
                border-top: 1px solid #ddd;
            }
          &>div:first-child{
            width: 20%;
            border-right: 1px solid #ddd;
            @include themify($themes){
              background-color: themed(theme-third-color);
            }
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &>div:last-child{
            width: 80%;
            padding-left: 10px;
            display: flex;
            align-items: center;
          }
        }
      }
      th,td{
        height: 40.5px;
      }
      .tableBodyRow {
        &>td:first-child{
          @include themify($themes){
            background-color: themed(theme-third-color);
          }
        width: 60px !important;
        }
      }
    }
}