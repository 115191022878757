@import '../../../scss/web_base';

.ThirdPartyTopUpManagementWrapper{
    color: #5d6d7b;
    height: max-content;
    display: flex;
    background-color: $white;
    border-radius: 5px;
    flex-direction: column;
    width: 100%;

    .boDuanColor{
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      &.red{
        background-color: #ec6941;
      }
      &.green{
        background-color: #60990a;
      }
      &.blue{
        @include themify($themes){
          background-color: themed(theme-primary-color);
        }
      }
    }

    @include themify($themes){
      font-family: themed(font-family);
    }
    .subselection {
      position:absolute;
      top:5;
      z-index:1;
    }

    .filterWrapper {
        color: #5d6d7b;
        font-size: 12px;
        border-radius: 3px;
        border: 1px solid #dcdcdc;
        @include themify($themes){
            background-color: themed(theme-third-color);
        }
   
        .el-input{
          width: 120px;
          margin-left: 5px;
        }

        .el-input__inner{
          height: 30px !important;
        }
   
        .el-select-dropdown__item.selected,
        .el-select-dropdown__item.selected.hover {
          font-weight: 700;
          @include themify($themes){
            color: themed(theme-primary-color);
            background-color: themed(drop-down-hover);
          }
        }

        .el-select {
          .el-input__inner:hover, .el-input__inner:focus {
            @include themify($themes){
              border-color: themed(theme-primary-color);  
              background-color: themed(drop-down-hover);
            }
          }
          .el-input__inner {      
            width: 115px;
            padding-right: 10px;
            .is-active {
            @include themify($themes){
              color: themed(theme-primary-color);
              border-color:themed(theme-primary-color);
            }
          }
          }   
        }  
        .el-select-dropdown__item:hover {
          @include themify($themes){
            background-color: themed(drop-down-hover);
          }
        }
        .el-select-dropdown {
          border-radius: 4px !important;
        }
        .betQueryDatepicker {
          .el-input {
            width: 250px !important;
          }
        }
        .dropdownred {
          color:white;
            .el-input__inner:hover, .el-input__inner:focus {
              @include themify($themes){
                border-color: themed(theme-primary-color);  
                background-color: themed(search-button-color);
              }
            }
            .el-input__inner {      
              color: white;
              font-weight: 700;
              @include themify($themes) {
                background: themed(search-button-color);
                border: 1px solid themed(search-button-color);
              }
            }
            ::placeholder {
              color: white;
            }
          }

      }

      .operationBtn{
        border-radius: 5px;
        padding: 7px 10px;
        line-height: 1;
        white-space: nowrap;
        cursor: pointer;
        color: #FFF;
        @include themify($themes){
          background: themed(theme-primary-color);
          border-color:themed(theme-primary-color);
        }
        &:hover{
            @include themify($themes){
                background: themed(theme-secondary-color);
                border-color:themed(theme-secondary-color);
              }
        }
    
        &.stopAndStart{
          background-color: #E6A23C;
          border-color: #E6A23C;
          &:hover{
              background: #ebb563 !important;
              border-color: #ebb563 !important;
          }
      }
    
        &.editAndSave{
          @include themify($themes){
              background: themed(theme-primary-color);
              border-color:themed(theme-primary-color);
            }
          &:hover{
              @include themify($themes){
                  background: themed(theme-secondary-color);
                  border-color:themed(theme-secondary-color);
                }
          }
      }
    
    
        &.searchBtn{
          background-color: #F56C6C;
          border-color: #F56C6C;
          &:hover{
              background: #f78989 !important;
              border-color: #f78989 !important;
          }
          .plus_icon{
            margin-right: 3px;
            border-radius: 50%;
            padding: 0px 2px;
            display: inline;
            background-color: white !important;
          .el-icon-plus{
            color: #F56C6C;
          }
        }
        }
      }
}