@import '../../../../scss/web_base';

.MaxGoodsWarpper{
  font-size: 12px;
  color: #5d6d7b;
  position: relative;
    @include themify($themes){
      font-family: themed(font-family);
    }

    .menuRow{
      border-radius: 3px;
      border: 1px solid #dcdcdc;
      padding: 5px 10px;
      width: 100%;
      display: flex;
      @include themify($themes){
          background-color: themed(theme-third-color);
        }
      .currentSelected{
          border: 1px solid #d2d7e0;
          border-radius: 3px;
          cursor: pointer;
          position: relative;
          width: 120px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          padding: 0;
          background-color: white;
          @include themify($themes){
              color: themed(theme-primary-color);
            }
          &:hover{
              @include themify($themes){
                  background-color: themed(theme-primary-color) !important;
                }
              color: white !important;
              .arrow_down{
                  border-top: 5px solid white !important;
              }
          }
          .arrow_down{
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              display: inline-block;
              margin: 2px 10px;
              @include themify($themes){
                  border-top: 5px solid themed(theme-primary-color) !important;
                }
          }
          .bogusBetWarningMenuWrapper{
              position: absolute;
              z-index: 2;
          }
      }
  }

    .footer {
      width: 100%;
      position: fixed;
      bottom:24px;
      left:0;
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      @include themify($themes){
        background-color: themed(theme-third-color);
        color: themed(table-header-text-color);
      }
    }
    .kuaijieInputStyle{
      width: 100px;
    }
    
    .operationBtn {
      border-radius: 5px;
      padding: 7px 15px;
      white-space: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: #FFF;

      &.editAndSave {
        @include themify($themes) {
          background: themed(theme-primary-color);
          border-color: themed(theme-primary-color);
        }

        &:hover {
          @include themify($themes) {
            background: themed(theme-secondary-color);
            border-color: themed(theme-secondary-color);
          }
        }
      }

      &.add {
        background-color: #67C23A;
        border-color: #67C23A;

        &:hover {
          background: #85ce61 !important;
          border-color: #85ce61 !important;
        }
      }

      &.remove {
        background-color: #F56C6C;
        border-color: #F56C6C;

        &:hover {
          background: #f78989 !important;
          border-color: #f78989 !important;
        }
      }
    }
}