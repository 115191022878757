@import '../../../scss/web_base';

.HandleAgentRebateWrapper{
    color: #5d6d7b;
    height: max-content;
    display: flex;
    background-color: $white;
    border-radius: 5px;
    flex-direction: column;
    width: 100%;
    @include themify($themes){
      font-family: themed(font-family);
    }
    .menuSelectedText {
      width: 90px !important;
    }
    .subselection {
      position:absolute;
      top:5;
      z-index:1;
    }
    .hide {
      display: none;
    }
    .menuWrapper {
      background: white;
      height: 30px;
      width: 120px !important;
      font-size: 12px;
      text-align: left;
      border-radius: 3px;
      padding: 5px 10px 5px 10px;
      color:black;
    }

    .filterWrapper {
      color: #5d6d7b;
      font-size: 12px;
      border-radius: 3px;
      border: 1px solid #dcdcdc;
        @include themify($themes){
            background-color: themed(theme-third-color);
        }
   
        .el-input{
          width: 120px;
          margin-left: 5px;
        }

        .el-input__inner{
          height: 30px !important;
        }
   
        .el-select-dropdown__item.selected,
        .el-select-dropdown__item.selected.hover {
          font-weight: 700;
          @include themify($themes){
            color: themed(theme-primary-color);
            background-color: themed(drop-down-hover);
          }
        }

        .el-select {
          .el-input__inner:hover, .el-input__inner:focus {
            @include themify($themes){
              border-color: themed(theme-primary-color);  
              background-color: themed(drop-down-hover);
            }
          }
          .el-input__inner {      
            width: 115px;
            padding-right: 10px;
            .is-active {
            @include themify($themes){
              color: themed(theme-primary-color);
              border-color:themed(theme-primary-color);
            }
          }
          }   
        }  
        .el-select-dropdown__item:hover {
          @include themify($themes){
            background-color: themed(drop-down-hover);
          }
        }
        .el-select-dropdown {
          border-radius: 4px !important;
        }
        .betQueryDatepicker {
          .el-input {
            width: 250px !important;
          }
        }
        .dropdownred {
          color:white;
            .el-input__inner:hover, .el-input__inner:focus {
              @include themify($themes){
                border-color: themed(theme-primary-color);  
                background-color: themed(search-button-color);
              }
            }
            .el-input__inner {      
              color: white;
              font-weight: 700;
              @include themify($themes) {
                background: themed(search-button-color);
                border: 1px solid themed(search-button-color);
              }
            }
            ::placeholder {
              color: white;
            }
          }

      }
      .searchButton {
        color: $white;
        font-weight: 700;
        border-radius: 5px;
        outline: none;
        font-size: 12px;
        position: relative;
        padding: 5px 15px 5px 15px;
        box-shadow: none;
        cursor: pointer;

        &.find{
          background: #f56f62;
          border: 1px solid #f56f62;
        }
        &.generate{
          background-color: #E6A23C;
          border-color: #E6A23C;
        }
        &.handle{
          background-color: #409EFF;
          border-color: #409EFF;
        }
      }
}