@import '../../scss/web_base';

.buHuoWrapper {
display: flex;
justify-content: center;
align-items: center;
.buhuoDone{
    @include themify($themes){
        color: themed(theme-primary-color);
        border-bottom: 1px solid themed(theme-primary-color);
    }
}
}