@import '../../../scss/web_base';

.topUpPanelWrapperWeb {
  width: 550px;
  @include themify($themes) {
    font-family: themed(font-family);
  }
  .modalHeader {
    line-height: 45px;
    color: $white;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
      font-family: themed(font-family);
    }
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
    .closeIcon {
      cursor: pointer;
    }
  }
  .modalContent {
    font-size: 16px;
    height: max-content;
    margin: 0;
    flex-direction: column;
    .successTick {
      height: 7em;
      width: 7em;
    }
    .row {
      display: flex;
      width: 100%;
      justify-content: center;
      &.orderIdWrapper {
        @include themify($themes) {
          color: themed(top-up-modal-content-orderId-text-color);
        }
        letter-spacing: 2px;
        font-weight: bold;
      }
      &.attentionMessage {
        font-size: 13px;
        font-weight: bold !important;
        .attentionText {
          @include themify($themes) {
            color: themed(top-up-modal-content-attention-text-color);
          }
        }
      }
      .detailTable {
        width: 80%;
        border: none;
        tbody {
          tr {
            td {
              padding: .3rem .75rem;
              border: none;
              &:first-child {
                width: 35%;
              }
              &:last-child {
                width: 25%;
              }
              &.label {
                @include themify($themes) {
                  color: themed(top-up-modal-content-label-text-color)
                }
              }
              &.value {
                @include themify($themes) {
                  color: themed(top-up-modal-content-value-text-color)
                }
                font-weight: bold;
              }
            }
          }
        }
      }
    }
    .reminderMessage,
    .successMessage {
      text-align: center;
      justify-content: center;
      align-items: center;
      display: flex;
      font-weight: bold;
      font-size: 14px;
      @include themify($themes) {
        color: themed(top-up-modal-content-message-text-color);
        background: themed(top-up-modal-content-message-background-color);
      }
    }
    .successMessage {
      width: 320px
    }
    .reminderMessage {
      width: 435px;
    }
    .copyButton {
      @include themify($themes) {
        background-color: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
      font-size: 16px;
      padding: 1px 15px;
    }

  }
  .modalFooter{
    font-size: 16px;
    height: max-content;
    margin: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include themify($themes) {
      background-color:themed(theme-third-color);
    }
    @include themify($themes) {
      border-top: 1px solid #dcdcdc
    }
    .cancelButton {
      @include themify($themes){
        color: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
      cursor: pointer;
      background-color: $white;
      width: 120px;
      height: 36px;
      border-radius: 4px;
      font-size: 16px;
      letter-spacing: 1px;
      text-align: center;
      line-height: 36px;
      padding: 0 .75rem;
      border: 0;
    }
    .completeTopUpButton {
      width: 120px;
      height: 36px;
      border-radius: 4px;
      font-size: 16px;
      letter-spacing: 1px;
      text-align: center;
      line-height: 36px;
      padding: 0 .75rem;
      @include themify($themes){
        background: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
    }
  }
}