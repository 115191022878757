@import '../../scss/web_base';

.resultWrapper  {
    font-size: 12px;
    padding: 12px 12px 60px 12px;
    color: #5d6d7b;
    position: relative;
    @include themify($themes) {
      font-family: themed('fontFamily');
    }
    .menuRow{
      padding: 5px 10px;
      width: 100%;
      display: flex;
      align-items: center;
      @include themify($themes){
        background-color: themed(theme-third-color);
      }
  
      .currentSelected{
          border: 1px solid #d2d7e0;
          border-radius: 3px;
          cursor: pointer;
          position: relative;
          width: 120px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          padding: 0;
          background-color: white;
          @include themify($themes){
            color: themed(theme-primary-color);
          }
          &:hover{
            @include themify($themes){
              background-color: themed(theme-primary-color) !important;
            }
              color: white !important;
              .arrow_down{
                  border-top: 5px solid white !important;
              }
          }
          .arrow_down{
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              display: inline-block;
              margin: 2px 10px;
              @include themify($themes){
               border-top: 5px solid themed(theme-primary-color);
              }
          }
          .bogusBetWarningMenuWrapper{
              position: absolute;
              z-index: 2;
          }
      }
  }

    .resultHeader{
        color: $white;
        font-size: 16px;
        height: 34px;
        line-height: 34px;
        width: 100%;
        @include themify($themes){
          background-color: themed(theme-primary-color);
        }
      }

    #rangeButton4{
      display: none !important;
    }
    .timeContainer{
      display: none !important;
    }
    .square_1 {
      @include themify($themes){
          border-color: themed(table-square-1) !important;
          background-color: themed(table-square-1);
      }
    }
    .square_2 {
        @include themify($themes){
            border-color: themed(table-square-2) !important;
            background-color: themed(table-square-2);
        }
    }
    .square_3 {
        @include themify($themes){
            border-color: themed(table-square-3) !important;
            background-color: themed(table-square-3);
        }
    }
    .square_4 {
        @include themify($themes){
            border-color: themed(table-square-4) !important;
            background-color: themed(table-square-4);
        }
    }
    .square_5 {
        @include themify($themes){
            border-color: themed(table-square-5) !important;
            background-color: themed(table-square-5);
        }
    }
    .square_6 {
        @include themify($themes){
            border-color: themed(table-square-6) !important;
            background-color: themed(table-square-6);
        }
    }
    .square_7 {
        @include themify($themes){
            border-color: themed(table-square-7) !important;
            background-color: themed(table-square-7);
        }
    }
    .square_8 {
        @include themify($themes){
            border-color: themed(table-square-8) !important;
            background-color: themed(table-square-8);
        }
    }
    .square_9 {
        @include themify($themes){
            border-color: themed(table-square-9) !important;
            background-color: themed(table-square-9);
        }
    }
    .square_10 {
        @include themify($themes){
            border-color: themed(table-square-10) !important;
            background-color: themed(table-square-10);
        }
    }
    .square {
        width: 21px;
        height: 20px;
        display: block;
        margin: 0 auto;
        text-align: center;
        color: $white;
        font-size: 12px;
        line-height: 15px;
        font-weight: bold;
        border: 3px solid;
        border-radius: 5px;
    }
    .ball{
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin: 0 auto;
      text-align: center;
      line-height: 10px;
      font-size: 10px;
      font-weight: bold;
      display: block;
      &.ballBlueLight{
        width: 25px;
        height: 25px;
        line-height: 17px;
        @include themify($themes){
            background-color: themed(BJK8-ball-light);
            border: 4px solid themed(BJK8-ball-light) !important;
            color: themed(BJK8-ball-text-color) !important;
        }
      }
      &.ballBlueDark{
        width: 25px;
        height: 25px;
        line-height: 17px;
        @include themify($themes){
            background-color: themed(BJK8-ball-dark);
            border: 4px solid themed(BJK8-ball-dark) !important;
        }
        color: $white !important;
      }
  }

  .kl10Header{
    padding: 8px 4px;
  }
  .gxk10Header{
    padding: 8px 4px;
  }
  .pk10Header{
    padding: 8px 4px;
  }
  .fantanHeader{
    padding: 8px 4px;
  }
  .gxk10Num,
  .kl10Num,
  .pk10Num{
    &>div{
      flex: 1;
      width: 25px !important;
      height: 100%;
      padding: 6.5px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    &>div:not(:last-child){
      @include themify($themes){
          border-right: 1px solid themed(table-border-color);
      }
    }
  }
  .gxk10ZH,
  .pk10GYH{
    &>div{
      flex: 1;
      align-self: stretch !important;
      height: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    &>div:not(:last-child){
      @include themify($themes){
          border-right: 1px solid themed(table-border-color);
      }
    }
  }
  .gxk10LH,
  .pk10LH{
    &>div{
      flex: 1;
      align-self: stretch !important;
      height: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    &>div:not(:last-child){
      @include themify($themes){
          border-right: 1px solid themed(table-border-color);
      }
    }
  }
  .redText{
    @include themify($themes){
      color: red
    }
  }
  .blueText{
    @include themify($themes){
      color: blue
    }
  }
  .greenText{
    @include themify($themes){
      color: green
    }
  }
  .redBoDuan{
    @include themify($themes){
      background-color: red
    }
    color: $white;
  }
  .blueBoDuan{
    @include themify($themes){
      background-color: blue
    }
    color: $white;
  }
  .greenBoDuan{
    @include themify($themes){
      background-color: green
    }
    color: $white;
  }
  .hk6Header{
    width: 100%;
    &>div:not(:last-child){
      @include themify($themes){
        border-bottom: 1px solid themed(table-border-color);
      }
    }
    &>div{
      .innerCellWrapper{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &>div:not(:last-child){
        @include themify($themes){
          border-right: 1px solid themed(table-border-color);
        }
      }
      height: 45px;
      align-items: center;
      width: 100%;
      display: flex;
      &>span{
        flex: 1;
        height: 45px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
      &>span:not(:last-child){
        @include themify($themes){
          border-right: 1px solid themed(table-border-color);
        }
      }
    }
  }

  .singleCol{
    padding: 8px 0px;
    width: 27px !important;
    flex: none !important;
  }
  .doubleCol{
    padding: 8px 0px;
    width: 40px !important;
    flex: none !important;
  }
  .hk6BallCol{
    .innerCellWrapper{
      width: 100%;
      height: 100%;
      padding: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &>div{
      flex: 1;
      display: inline-flex;
      align-items: center;
      height: 100% !important;
      width: 100% !important;
      &>div:not(:last-child){
        @include themify($themes){
          border-right: 1px solid themed(table-border-color);
        }
      }
    }
    &>div:not(:last-child){
      @include themify($themes){
        border-right: 1px solid themed(table-border-color);
      }
    }
  }
  .bjk8BallCol{
    &>div{
      flex: 1;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100% !important;
      width: 100% !important;
      &>div:not(:last-child){
        @include themify($themes){
          border-bottom: 1px solid themed(table-border-color);
        }
      }
    }
    .innerCellWrapper{
      width: 100%;
      height: 100%;
      padding: 5px 0px;
    }
    &>div:not(:last-child){
      @include themify($themes){
        border-right: 1px solid themed(table-border-color);
      }
    }
}
    .dateCol{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2px 0px;
      &>div{
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
  }
  .circle{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: block;
    margin: 0px auto;
    @include themify($themes){
        color: themed(table-ssc-circle-text-color);
        border: 4px solid themed(table-ssc-circle-border-color);
    }
    text-align: center;
    line-height: 17px;
    font-size: 10px;
    font-weight: bold;
    &.circleRed {
        border: 4px solid $ballRed !important;
    }
    &.circleBlue {
        border: 4px solid $ballBlue !important;
    }
    &.circleGreen {
        border: 4px solid $ballGreen !important;
    }
}
  .sscHeader{
    padding: 8px 4px;
  }
  .fantanBall,
  .sscBallAndZh{
    &>div{
      flex: 1;
      width: 25px !important;
      height: 100%;
      padding: 0px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    &>div:not(:last-child){
      @include themify($themes){
          border-right: 1px solid themed(table-border-color);
      }
    }
    > .circleBlue {
      border: 4px solid $ballBlue !important;
    }
  }
}