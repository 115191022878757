@import "../../scss/web_base";

.headerMenu {
  height: 100%;
  color: $white;
  align-items: flex-end;
  display: flex;
  @include themify($themes){
    font-family: themed(font-family);
  }

  .menuWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-weight: 600 !important;

    .menuItem {
      cursor: pointer;
      font-size: 12px;
      padding: 8px 0;
      justify-content: center;
      &:last-child {
        > span {
          border: 0;
        }
      }
      &> span {
        border-right: 1px solid rgba($white, 0.2);
        width: 100%;
        text-align: center;
        padding: 0px 8px;
      }
    }
    
    &> div {
      display: flex;
      flex-direction: row;
    }
  }

  .selected {
    border-radius: 4px 4px 0 0;
    @include themify($themes){
      color: themed(theme-primary-color);
      background-color: themed(header-selected-menu);
    }
  }
}