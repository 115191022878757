@import '../../../scss/web_base';

.PanKouSettingModalWrapperWeb {
  width: 500px;
  @include themify($themes) {
    font-family: themed(font-family);
  }
  .resultNumCell{
    width: 25px !important;
    padding: 0 !important;
    &>input{
      padding: 0px !important;
      text-align: center;
    }
  }
  .modalHeader {
    line-height: 45px;
    color: $white;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
      font-family: themed(font-family);
    }
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
    .closeIcon {
      cursor: pointer;
    }
  }
  .modalContent {
    font-size: 12px;
    .inputStyle{
      width: 216px;
    }
    .orderInformationTable{
      border: 1px solid #ddd;
      width: 100%;
      .evenRowStyle{
          display: flex;
          &>div:nth-child(odd){
            @include themify($themes) {
              background-color:themed(theme-third-color);
            }
              width: 17%;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              padding-right: 20px;
          }
          &>div:nth-child(even){
              flex: 1;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding-left: 20px;
          }
          &>div:not(:last-child){
              border-right: 1px solid #ddd;
          }
      }
      &>div{
          min-height: 40px;
      }
      &>div:not(:last-child){
          border-bottom: 1px solid #ddd;
      }
      .orderInformationRowBackGroundColor{
        @include themify($themes) {
          background-color:themed(theme-third-color);
        }
      }
  }
  }
  .modalFooter {
    font-size: 16px;
    height: max-content;
    margin: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    @include themify($themes) {
      border-top: 1px solid #dcdcdc
    }
    .confirmButton {
      width: 120px;
      height: 36px;
      color: $white;
      border-radius: 4px;
      font-size: 16px;
      text-align: center;
      @include themify($themes){
        background-color: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
    }
  }
}