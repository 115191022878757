@import '../../../scss/web_base';

.confirmModalWrapperWeb {
  width: 550px;
  @include themify($themes) {
    font-family: themed(font-family);
  }
  .modalHeader {
    line-height: 45px;
    color: $white;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
      font-family: themed(font-family);
    }
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
    .closeIcon {
      cursor: pointer;
    }
  }
  .modalContent {
    .el-select-dropdown__item.selected,
    .el-select-dropdown__item.selected.hover {
      font-weight: 700;
      @include themify($themes){
        color: themed(theme-primary-color);
        background-color: themed(drop-down-hover);
      }
    }

    .el-select {
      .el-input__inner:hover, .el-input__inner:focus {
        @include themify($themes){
          border-color: themed(theme-primary-color);  
          background-color: themed(drop-down-hover);
        }
      }
      .el-input__inner {      
        padding-right: 10px;
        .is-active {
        @include themify($themes){
          color: themed(theme-primary-color);
          border-color:themed(theme-primary-color);
        }
      }
      }   
    }  
    .el-select-dropdown__item:hover {
      @include themify($themes){
        background-color: themed(drop-down-hover);
      }
    }
    .el-select-dropdown {
      border-radius: 4px !important;
    }
    font-size: 16px;
    height: max-content;
    margin: 0;
    flex-direction: column;
    padding-bottom: 2em;
    .confirmText{
      text-align: center;
    }
    .exclamationIcon {
      height: 7em;
      width: 7em;
      circle{
        @include themify($themes){
          fill: themed(theme-secondary-color) !important;
        }
      }
    }
    .successText {
      @include themify($themes) {
        color: themed(success-modal-text-color)
      }
    }
  }
  .modalFooter {
    font-size: 16px;
    height: max-content;
    margin: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include themify($themes) {
      border-top: 1px solid #dcdcdc
    }
    .confirmButton {
      width: 120px;
      height: 36px;
      color: $white;
      border-radius: 4px;
      font-size: 16px;
      text-align: center;
      @include themify($themes){
        background-color: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
    }
    .cancelButton {
      width: 120px;
      height: 36px;
      border-radius: 4px;
      font-size: 16px;
      text-align: center;
      background-color: $white;
      @include themify($themes){
        color: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
      &:hover {
        background-color: $white;
      }
    }
  }
}
